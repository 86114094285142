import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Modal, Button, ControlLabel, FormControl} from 'react-bootstrap';
import Select from 'react-select';
import RndModal from '../../../../components/modals/RndModal';
import styled from "styled-components";

const FILE_SIZE_UNITS = {
    'bytes': {'multiplier': 1},
    'KB': {'multiplier': 1024},
    'MB': {'multiplier': 1024*1024},
    'GB': {'multiplier': 1024*1024*1024},
    'TB': {'multiplier': 1024*1024*1024*1024},
}
const UNIT_SIZE_OPTIONS = Object.keys(FILE_SIZE_UNITS).map((k) => ({value: k, label: k}));

class AsperaLinkFileSizeModal extends Component {
    state = {
        fileSizeValue: '',
        unitSizeValue: 'bytes'
    };

    handleSave = () => {
        const {onSave} = this.props;
        const {fileSizeValue, unitSizeValue} = this.state;
        const multiplier = FILE_SIZE_UNITS[unitSizeValue].multiplier;
        const fileSize = Math.round(multiplier * fileSizeValue);
        onSave(fileSize);
    };

    handleClose = () => {
        const {onClose} = this.props;
        onClose();
    };

    componentWillUnmount() {
        this.handleClose();
    }

    handleChangeFileSize = event => {
        this.setState({ fileSizeValue: event.target.value });
    };

    render() {
        const {fileSizeValue, unitSizeValue} = this.state;
        return (
            <RndModal
                show={true}
                onHide={this.handleClose}
                backdrop="static"
                enforceFocus={false}
                minHeight={350}
            >
                <Modal.Header closeButton>
                    <Modal.Title>Aspera Download Size</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="container-fluid">
                        <ControlLabel>Enter size:</ControlLabel>
                        <FileSizeInputContainer>
                            <div>
                                <FormControl
                                    type="number"
                                    step="0.001"
                                    min="0"
                                    value={fileSizeValue}
                                    onChange={this.handleChangeFileSize}
                                    onBlur={this.handleChangeFileSize}
                                />
                            </div>
                            <Select
                                placeholder="Select unit"
                                options={UNIT_SIZE_OPTIONS}
                                value={unitSizeValue}
                                onChange={selected => this.setState({unitSizeValue: selected.value})}
                                clearable={false}
                                searchable={false}
                            />
                        </FileSizeInputContainer>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button bsStyle="primary" onClick={this.handleSave}>Save</Button>
                    <Button bsStyle="default" onClick={this.handleClose}>Cancel</Button>
                </Modal.Footer>
            </RndModal>
        );
    }
}

export const FileSizeInputContainer = styled.div`
  display: flex;
  flex-direction: row;
  
  div input {
    height: 36px;
  }
  
  div.Select {
    width: 100px;
    margin-left: 5px;
  }
`;

AsperaLinkFileSizeModal.propTypes = {
    onSave: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
};

export default AsperaLinkFileSizeModal;
