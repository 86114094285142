import $ from 'jquery';
import config from '../../../config';
import {
  getFiltersQuery, getActionsUserPermissionsWithoutRoots, getActiveFsRoots, showLinkToMemfisModalForAutoDownloads,
  closeLinkWOAlreadyLinkedInBurbankModal, getLockAndServiceStatusesAjax, sendIngestOperationTypes,
  STORAGE_MNG_SET_CURRENT_VIEW, STORAGE_MNG_ACTIONS_REQUESTORS_SHOW
} from '../StorageManager/actions';
import {
  VIEW_ADVANCED_PREDICTIVE, FS_ACTION_TYPE__SET_DOWNLOAD_PRIORITY, FS_ACTION_TYPE__DELIVERY_MEMFIS_WO
} from '../StorageManager/constants';
import qs from 'qs';
import {
  ALL_FILTERS, FILTER_WO_MEMFIS, FILTER_DAYS_BACK_FROM, FILTER_DOWNLOAD_TYPE, FILTER_DOWNLOAD_TYPE_UPLOAD,
  STATUS_KEY_DOWNLOADING, DownloadStatus, FILTERS_PRESET_ID
} from './constants';
import {showError, showSuccess, showInfo} from '../../layouts/actions';
import {getErrorMessage, formatBytes} from '../../utils';
import {isEqualsItems, calcMaxPriorityAndMarkLinksToChangePriority} from './utils';
import {DELIVERY_PAGE__EDIT, DELIVERY_PAGE__APPROVE, DELIVERY_PAGE__VIEW} from '../DeliveryManager/constants';

export const AUTO_DOWNLOADS_SET_LOADING = 'AUTO_DOWNLOADS_SET_LOADING';
export const AUTO_DOWNLOADS_FILTERS_PRESETS_SELECT = 'AUTO_DOWNLOADS_FILTERS_PRESETS_SELECT';
export const AUTO_DOWNLOADS_FILTERS_PRESETS_SAVE = 'AUTO_DOWNLOADS_FILTERS_PRESETS_SAVE';
export const AUTO_DOWNLOADS_FILTERS_PRESETS_DELETE = 'AUTO_DOWNLOADS_FILTERS_PRESETS_DELETE';
export const AUTO_DOWNLOADS_FILTERS_PRESETS_SET_ADVANCED_MODE = 'AUTO_DOWNLOADS_FILTERS_PRESETS_SET_ADVANCED_MODE';
export const AUTO_DOWNLOADS_FILTERS_PRESETS_SET_AS_DEFAULT = 'AUTO_DOWNLOADS_FILTERS_PRESETS_SET_AS_DEFAULT';
export const AUTO_DOWNLOADS_FILTERS_PRESETS_SET = 'AUTO_DOWNLOADS_FILTERS_PRESETS_SET';
export const AUTO_DOWNLOADS_SET_ASSETS_STATUSES = 'AUTO_DOWNLOADS_SET_ASSETS_STATUSES';
export const AUTO_DOWNLOADS_GET_EMAILS = 'AUTO_DOWNLOADS_GET_EMAILS';
export const AUTO_DOWNLOADS_GET_EMAILS_UPDATE_LINK = 'AUTO_DOWNLOADS_GET_EMAILS_UPDATE_LINK';
export const AUTO_DOWNLOADS_CHANGE_CURRENT_PAGE = 'AUTO_DOWNLOADS_CHANGE_CURRENT_PAGE';
export const AUTO_DOWNLOADS_CHANGE_PAGE_SIZE = 'AUTO_DOWNLOADS_CHANGE_PAGE_SIZE';
export const AUTO_DOWNLOADS_CHANGE_FILTER_VALUE = 'AUTO_DOWNLOADS_CHANGE_FILTER_VALUE';
export const AUTO_DOWNLOADS_LINK_TO_MEMFIS_WO_IS_SAVED = 'AUTO_DOWNLOADS_LINK_TO_MEMFIS_WO_IS_SAVED';
export const AUTO_DOWNLOADS_SHOW_SELECT_FOLDER_MODAL = 'AUTO_DOWNLOADS_SHOW_SELECT_FOLDER_MODAL';
export const AUTO_DOWNLOADS_MANUAL_UPLOAD_IS_DONE = 'AUTO_DOWNLOADS_MANUAL_UPLOAD_IS_DONE';
export const AUTO_DOWNLOADS_COMPLETE_DOWNLOAD_FOR_MULTIPLE_WOS = 'AUTO_DOWNLOADS_COMPLETE_DOWNLOAD_FOR_MULTIPLE_WOS';
export const DOWNLOAD_MNG_CHANGE_SELECTED_ITEMS = 'DOWNLOAD_MNG_CHANGE_SELECTED_ITEMS';
export const DOWNLOAD_MNG_INGEST_SHOW_MODAL = 'DOWNLOAD_MNG_INGEST_SHOW_MODAL';
export const DOWNLOAD_MNG_CLEAR_ALL = 'DOWNLOAD_MNG_CLEAR_ALL';
export const DOWNLOAD_MNG_SET_ASSET_DATA = 'DOWNLOAD_MNG_SET_ASSET_DATA';
export const MAM_UPLOADS_LINK_TO_MEMFIS_WO_IS_SAVED = 'MAM_UPLOADS_LINK_TO_MEMFIS_WO_IS_SAVED';
export const MAM_UPLOADS_FILES_SHOW = 'MAM_UPLOADS_FILES_SHOW';
export const AUTO_DOWNLOADS_SET_NOT_LOCKED_ROOTS = 'AUTO_DOWNLOADS_SET_NOT_LOCKED_ROOTS';
export const AUTO_DOWNLOADS_CHANGE_LINK_FIELD = 'AUTO_DOWNLOADS_CHANGE_LINK_FIELD';
export const DOWNLOAD_MNG_SHOW_DELIVERY_MODAL = 'DOWNLOAD_MNG_SHOW_DELIVERY_MODAL';

export const clearAllDownloadMng = () => ({type: DOWNLOAD_MNG_CLEAR_ALL});

export const saveSettingsToStorage = (name, value) => {
  const localSettings = JSON.parse(localStorage.getItem('downloadSettings'));
  localStorage.setItem('downloadSettings', JSON.stringify({...localSettings, [name]: value}));
  const sessionSettings = JSON.parse(sessionStorage.getItem('downloadSettings'));
  sessionStorage.setItem('downloadSettings', JSON.stringify({...sessionSettings, [name]: value}));
};

const resetFiltersInStorage = () => {
  const localSettings = JSON.parse(localStorage.getItem('downloadSettings')) || {};
  delete localSettings.filters;
  localStorage.setItem('downloadSettings', JSON.stringify({...localSettings}));

  const sessionSettings = JSON.parse(sessionStorage.getItem('downloadSettings')) || {};
  delete sessionSettings.filters;
  sessionStorage.setItem('downloadSettings', JSON.stringify({...sessionSettings}));
};

const resetLastFiltersInSessionStorage = () => {
  sessionStorage.removeItem('lastFiltersForAssetManager');
};

const getLockStatusesForAssetData = () => dispatch => {
  dispatch({type: AUTO_DOWNLOADS_SET_LOADING, name: 'getRootLockStatuses', loading: true});
  return getLockAndServiceStatusesAjax()
  .done(res => {
    const notLockedRoots = res && Array.isArray(res.FsRootLockStatuses) && res.FsRootLockStatuses.length ?
      res.FsRootLockStatuses.filter(r => r.LockStatus === 'NOT_LOCKED').map(r => r.FSRootID) : null;
    dispatch({type: AUTO_DOWNLOADS_SET_NOT_LOCKED_ROOTS, notLockedRoots});
  })
  .fail(error => {
    dispatch({type: AUTO_DOWNLOADS_SET_LOADING, name: 'getRootLockStatuses', loading: false});
    dispatch(showError(`Could not get lock statuses for active FS roots.  ${getErrorMessage(error)}`));
  });
};

export const getFiltersPresets = (storedPresetId, woMemfisFromUrl) => (dispatch, getState) => {
  const {usedFilters} = getState().downloadManager;
  dispatch({type: AUTO_DOWNLOADS_SET_LOADING, name: 'getFiltersPresets', loading: true});
  return $.ajax({
    method: 'GET',
    url: `${config.apiUrl}/v1/faspex/filters_presets`,
    data: {used_filters: JSON.stringify(usedFilters), wo_memfis: woMemfisFromUrl}
  })
  .done(res => {
    dispatch({type: AUTO_DOWNLOADS_FILTERS_PRESETS_SET, filtersPresets: Array.isArray(res) ? res : [], storedPresetId, woMemfisFromUrl});
  })
  .fail(error => {
    dispatch({type: AUTO_DOWNLOADS_SET_LOADING, name: 'getFiltersPresets', loading: false});
    dispatch(showError(`Could not get presets for filters.  ${getErrorMessage(error)}`));
  })
  .then(() => getState().downloadManager.selectedFiltersPreset)
  .then(selectedFiltersPreset => {
    if (!!selectedFiltersPreset) {
      resetFiltersInStorage();
      saveSettingsToStorage(FILTERS_PRESET_ID, selectedFiltersPreset.value);
    }
  });
};

export const setPresetAsDefault = () => (dispatch, getState) => {
  const {selectedFiltersPreset} = getState().downloadManager;
  dispatch({type: AUTO_DOWNLOADS_SET_LOADING, name: 'savePreset', loading: true});
  return $.ajax({
    method: 'PUT',
    url: `${config.apiUrl}/v1/faspex/filters_presets/${selectedFiltersPreset.value}/default`
  })
  .done(() => {
    dispatch({type: AUTO_DOWNLOADS_FILTERS_PRESETS_SET_AS_DEFAULT});
  })
  .fail(error => {
    dispatch({type: AUTO_DOWNLOADS_SET_LOADING, name: 'savePreset', loading: false});
    dispatch(showError(`Could not set preset as default.  ${getErrorMessage(error)}`));
  });
};

const updateAdvancedModeInURL = (history, isAdvancedModeFromPreset) => {
  const urlFilters = qs.parse(window.location.search.substr(1));
  const isAdvancedModeFromURL = urlFilters.advanced === '1';
  if (isAdvancedModeFromURL !== isAdvancedModeFromPreset) {
    if (isAdvancedModeFromPreset) {
      urlFilters.advanced = '1';
    } else {
      delete urlFilters.advanced;
    }
    history.replace({search: `${qs.stringify(urlFilters)}`});
    const lastFiltersFromStorage = JSON.parse(sessionStorage.getItem('lastFiltersForAssetManager')) || {};
    const isAdvancedModeFromStorage = String(lastFiltersFromStorage.advanced) === '1';
    if (isAdvancedModeFromStorage !== isAdvancedModeFromPreset) {
      if (isAdvancedModeFromPreset) {
        lastFiltersFromStorage.advanced = '1';
      } else {
        delete lastFiltersFromStorage.advanced;
      }
      sessionStorage.setItem('lastFiltersForAssetManager', JSON.stringify(lastFiltersFromStorage));
    }
    return true;
  }
  return false;
};

export const setAdvancedModeForPreset = (history, isAdvancedMode) => (dispatch, getState) => {
  const {selectedFiltersPreset} = getState().downloadManager;
  dispatch({type: AUTO_DOWNLOADS_SET_LOADING, name: 'savePreset', loading: true});
  return $.ajax({
    method: 'PUT',
    url: `${config.apiUrl}/v1/faspex/filters_presets/${selectedFiltersPreset.value}/advanced`,
    data: {
      is_advanced: isAdvancedMode
    }
  })
  .done(() => {
    dispatch({type: AUTO_DOWNLOADS_FILTERS_PRESETS_SET_ADVANCED_MODE, isAdvancedMode});
    if (updateAdvancedModeInURL(history, isAdvancedMode)) {
      dispatch(getEmails(history));
    }
  })
  .fail(error => {
    dispatch({type: AUTO_DOWNLOADS_SET_LOADING, name: 'savePreset', loading: false});
    dispatch(showError(`Could not ${isAdvancedMode ? '' : 're'}set advanced mode for preset.  ${getErrorMessage(error)}`));
  });
};

export const deletePresets = presetIDs => dispatch => {
  dispatch({type: AUTO_DOWNLOADS_SET_LOADING, name: 'savePreset', loading: true});
  return $.ajax({
    method: 'DELETE',
    url: `${config.apiUrl}/v1/faspex/filters_presets?id=${presetIDs.join(',')}`
  })
  .done(() => {
    dispatch({type: AUTO_DOWNLOADS_FILTERS_PRESETS_DELETE, presetIDs});
  })
  .fail(error => {
    dispatch({type: AUTO_DOWNLOADS_SET_LOADING, name: 'savePreset', loading: false});
    dispatch(showError(`Could not delete preset.  ${getErrorMessage(error)}`));
  });
};

const getAssetStatuses = () => dispatch => {
  dispatch({type: AUTO_DOWNLOADS_SET_LOADING, name: 'getAssetStatuses', loading: true});
  return $.ajax({
    method: 'GET',
    url: `${config.apiUrl}/v1/file_system/assets/statuses`
  })
  .done(res => {
    const statuses = (!Array.isArray(res) || !res.length) ? null : res.map(item => {
      const {status, statusNames} = item;
      const statusNamesArr = DownloadStatus[status] || (Array.isArray(statusNames) ? statusNames : [String(statusNames).toUpperCase()]);
      return {...item, statusNames: statusNamesArr};
    });
    const statusesWithChangePriority = !statuses ? null : statuses.filter(s => s.couldChangePriority);
    let statusesBeforeDownloading;
    if (statuses) {
      const statusDownloading = statuses.find(s => s.status === STATUS_KEY_DOWNLOADING);
      if (statusDownloading) {
        statusesBeforeDownloading = statuses.filter(s => s.ordinal < statusDownloading.ordinal);
      }
    }
    dispatch({type: AUTO_DOWNLOADS_SET_ASSETS_STATUSES, statuses, statusesWithChangePriority, statusesBeforeDownloading});
  })
  .fail(error => {
    dispatch({type: AUTO_DOWNLOADS_SET_LOADING, name: 'getAssetStatuses', loading: false});
    dispatch(showError(`Could not get assets statuses.  ${getErrorMessage(error)}`));
  });
};

const getAssetData = item => dispatch => {
  const {WOMemfis} = item;
  if (!WOMemfis) {
    return;
  }
  dispatch({type: AUTO_DOWNLOADS_SET_LOADING, name: 'getAssetData', loading: true});
  $.ajax({
    method: 'GET',
    url: `${config.apiUrl}/v1/file_system/asset_data`,
    data: {
      wo_memfis_id: WOMemfis,
      is_parallel: item.is_parallel,
			root_action_id: item.RootActionID
    }
  })
  .done(res => {
    dispatch({type: AUTO_DOWNLOADS_SET_LOADING, name: 'getAssetData', loading: false});
    dispatch({type: DOWNLOAD_MNG_SET_ASSET_DATA, data: res, WOMemfis});
    dispatch(getLockStatusesForAssetData());
  })
  .fail(error => {
    dispatch({type: AUTO_DOWNLOADS_SET_LOADING, name: 'getAssetData', loading: false});
    dispatch(showError(`Could not get asset data.  ${getErrorMessage(error)}`));
  });
};

export const refreshAssetData = () => (dispatch, getState) => {
  const {selectedItems} = getState().downloadManager;
  if (selectedItems && selectedItems.length === 1) {
    dispatch(getAssetData(selectedItems[0]));
  }
};

export const getAndShowIngestOperationTypes2 = () => (dispatch, getState) => {
  const {selectedItems} = getState().downloadManager;
  dispatch({type: AUTO_DOWNLOADS_SET_LOADING, name: 'ingestOperationTypes', loading: true});
  return $.ajax({
    method: 'GET',
    url: `${config.apiUrl}/v1/file_system/ingest_operation_types/${selectedItems[0].WOMemfis}`
  })
    .done(res => {
      const {operation_types, work_order} = res;
      dispatch({type: AUTO_DOWNLOADS_SET_LOADING, name: 'ingestOperationTypes', loading: false});
      const fileName = selectedItems[0].fileName || selectedItems[0].file_name || (selectedItems[0].links ? selectedItems[0].links[0].file_name : null);
      dispatch(showIngestModal(true, operation_types || {}, {...work_order, woMemfisId: selectedItems[0].WOMemfis}, null, {...selectedItems[0], key: fileName}));
    })
    .fail(error => {
      dispatch({type: AUTO_DOWNLOADS_SET_LOADING, name: 'ingestOperationTypes', loading: false});
      dispatch(showError(`Could not get the ingest operation types.  ${getErrorMessage(error)}`));
    });
};

export const onSetOperationTypeFromAssetManager = ingestData => (dispatch, getState) => {
  const {selectedItems} = getState().downloadManager;
  const {WOMemfis} = selectedItems[0];
  const loadingName = 'onSetOperationTypeFromAssetManager';
  dispatch({type: AUTO_DOWNLOADS_SET_LOADING, name: loadingName, loading: true});
  dispatch(sendIngestOperationTypes(ingestData, WOMemfis))
    .done(() => {
      dispatch(showIngestModal(false));
    })
    .always(() => {
      dispatch({type: AUTO_DOWNLOADS_SET_LOADING, name: loadingName, loading: false});
    });
};

export const changeSelectedItems = items => dispatch => {
  dispatch({type: DOWNLOAD_MNG_CHANGE_SELECTED_ITEMS, items});
  if (items && items.length === 1) {
    dispatch(getAssetData(items[0]));
  }
};

const applyEmails = (res, ajaxFilters) => (dispatch, getState) => {
  const {assetStatusesBeforeDownloading, assetStatusesWithChangePriority} = getState().downloadManager;
  const allowUploadData = ajaxFilters[FILTER_DOWNLOAD_TYPE].includes(FILTER_DOWNLOAD_TYPE_UPLOAD);
  dispatch({
    type: AUTO_DOWNLOADS_GET_EMAILS,
    appliedWoMemfis: ajaxFilters[FILTER_WO_MEMFIS],
    data: (res.data || []).map(item => {
      item.groupId = String(Math.random()).replace(/[^0-9]/, '');
      if (!item.links) {
        item.links = [];
      }
      const {is_deleted, links, isFileNameFromWO} = item;
      if (item.parallel_ingest && item.parallel_ingest.links) {
        item.parallel_ingest.links.forEach(p => {
          p.is_parallel = true;
          if (p.isFileNameFromWO === undefined) {
            p.isFileNameFromWO = isFileNameFromWO;
          }
        });
      }
      links.forEach(link => {
        const {client_name, is_nam} = link;
        link.clientNameAlt = `${client_name || ''}${is_nam ? ' (NAM)' : ''}`;
        if (link.isFileNameFromWO === undefined) {
          link.isFileNameFromWO = isFileNameFromWO;
        }
      });
      if (is_deleted && links.length === 1) {
        links[0].is_deleted = is_deleted;
      }
      if (links.length >= 1 && typeof item.Priority !== 'number' && assetStatusesBeforeDownloading) {
        item.Priority = calcMaxPriorityAndMarkLinksToChangePriority(links, assetStatusesBeforeDownloading, assetStatusesWithChangePriority);
      }
      if (links.length > 1) {
        item.WOMemfis = links[0].WOMemfis;
        item.client_name = links[0].client_name;
        item.clientNameAlt = links[0].clientNameAlt;
      }
      return item;
    }),
    manualData: res.manualData || [],
    uploadData: !allowUploadData ? [] : (res.uploadData || []).filter(item => item.uploadID).map(item => {
      const {uploadID, woMemfis, clientName, file_size_bytes, file_size} = item;
      return {
        ...item,
        groupId: `upload-id-${uploadID}`,
        upload_id: uploadID,
        WOMemfis: woMemfis,
        client_name: clientName,
        file_size: formatBytes(file_size_bytes) || file_size
      };
    }),
    lastCrawlTS: res.lastCrawlTS,
    lastPackageTS: res.lastPackageTS,
    lastDownloadTS: res.lastDownloadTS,
    minRate: res.minRate,
    maxRate: res.maxRate,
    fileMakerURL: res.fileMakerURL
  });
};

export const changeFilterValue = (history, name, value) => (dispatch, getState) => {
  if (name === FILTER_WO_MEMFIS && getState().downloadManager.usedFilters[name]) {
    const urlFilters = qs.parse(window.location.search.substr(1));
    delete urlFilters[FILTER_WO_MEMFIS];
    history.replace({search: qs.stringify(urlFilters)});
  }

  let preparedValue = value;
  if (Array.isArray(value)) {
    preparedValue = value.map(item => item.value || item);
  }

  dispatch({type: AUTO_DOWNLOADS_CHANGE_FILTER_VALUE, name, value: preparedValue});
};

export const getEmails = (history, useLastFiltersFromStorage = false/* when first load then is true */) => (dispatch, getState) => {
  config.versionNumber && $.getScript(`${config.portalBaseUrl || ''}/version.js`, function(data, textStatus, jqxhr) {
    let temp;
    if (data && (temp = /[']([0-9.]+)[']/.exec(data)) && temp.length > 1) {
      const lastVersionNumber = temp[1];
      if (config.versionNumber !== lastVersionNumber) {
        window.location.reload();
      }
    } else {
      console.error(data, textStatus, jqxhr);
    }
  }).fail(console.error);

  const urlFilters = qs.parse(window.location.search.substr(1));
  const reissueFilters = useLastFiltersFromStorage && urlFilters.rsf === '1';
  Object.keys(urlFilters).forEach(key => {
    if (![FILTER_WO_MEMFIS, 'manual_aspera_link', 'advanced'].includes(key)) {
      delete urlFilters[key];
    }
  });
  const {usedFilters, selectedFiltersPreset, filtersPresets} = getState().downloadManager;
  const isAdvancedModeFromPreset = !!(selectedFiltersPreset || {}).is_advanced;
  const lastFiltersFromStorage = reissueFilters ? JSON.parse(sessionStorage.getItem('lastFiltersForAssetManager')) : null;
  const filters = lastFiltersFromStorage || {...usedFilters};
  const isAdvancedMode = !useLastFiltersFromStorage && selectedFiltersPreset ? isAdvancedModeFromPreset :
    /*isAdvancedModeFromPreset || */urlFilters.advanced === '1' || String((lastFiltersFromStorage || {}).advanced) === '1';
  if (isAdvancedMode) {
    filters.advanced = 1;
    urlFilters.advanced = '1';
  } else {
    delete urlFilters.advanced;
  }
  if (filters[FILTER_WO_MEMFIS] && !('no_redirect' in filters)) {
    filters.no_redirect = true;
  }
  if (!urlFilters[FILTER_WO_MEMFIS]) {
    delete urlFilters[FILTER_WO_MEMFIS];
  }
  history.replace({search: `${qs.stringify(urlFilters)}`});

  if (!reissueFilters) {
    sessionStorage.setItem('lastFiltersForAssetManager', JSON.stringify({...usedFilters, advanced: filters.advanced}));
  } else if (lastFiltersFromStorage) {
//    resetLastFiltersInSessionStorage();
    Object.keys(usedFilters).forEach(key => {
      if (key in lastFiltersFromStorage && lastFiltersFromStorage[key]) {
        dispatch(changeFilterValue(history, key, lastFiltersFromStorage[key]));
      }
    });
  }

  Object.keys(filters).forEach(filterKey => {
    if (Array.isArray(filters[filterKey])) {
      filters[filterKey] = filters[filterKey].map(item => item.value || item);
    }
  });
  const queryFilters = {...getFiltersQuery(filters)};

  const ajaxFilters = {...queryFilters};
  Object.keys(ajaxFilters).forEach(k => {
    const filter = ALL_FILTERS.find(item => item.name === k);
    if (filter && filter.isLocalFilter) {
      delete ajaxFilters[k];
    }
    if (k === FILTER_DAYS_BACK_FROM) {
      const {daysBack, fromDate} = ajaxFilters[k];
      delete ajaxFilters[k];
      ajaxFilters.days_back = daysBack;
      ajaxFilters.from_date = fromDate;
    }
  });

  if (urlFilters.manual_aspera_link) {
    ajaxFilters.aspera_link_id = urlFilters.manual_aspera_link;
    delete urlFilters['manual_aspera_link'];
    history.replace({search: `${qs.stringify(urlFilters)}`});
  }

  dispatch({type: AUTO_DOWNLOADS_SET_LOADING, name: 'table', loading: true});
  $.ajax({
    url: `${config.apiUrl}/v1/faspex/emails_new`,
    data: ajaxFilters,
    method: 'GET'
  })
    .done(res => {
      if (res.redirect_to_content) {
        dispatch(changeFilterValue(history, FILTER_WO_MEMFIS, ''));
        if (selectedFiltersPreset && selectedFiltersPreset.label === ajaxFilters[FILTER_WO_MEMFIS]) {
          const presetIDs = filtersPresets.filter(p => p.label === ajaxFilters[FILTER_WO_MEMFIS] || p.filters[FILTER_WO_MEMFIS] === ajaxFilters[FILTER_WO_MEMFIS])
            .map(p => p.value);
          if (presetIDs.length > 0) {
            dispatch(deletePresets(presetIDs));
          }
        }
        const {FSRootID, ContentID} = res.redirect_to_content;
        history.push(`/storage?root_id=${FSRootID}&content_id=${ContentID}`);
        return;
      }
      dispatch(changeSelectedItems([]));
      dispatch(getActionsUserPermissionsWithoutRoots()).always(() => {
        dispatch(getActiveFsRoots()).always(() => {
          dispatch(getAssetStatuses()).always(() => {
            dispatch(applyEmails(res, ajaxFilters));
            dispatch({type: AUTO_DOWNLOADS_SET_LOADING, name: 'table', loading: false});
          });
        });
      });
    })
    .fail(err => {
      dispatch({type: AUTO_DOWNLOADS_SET_LOADING, name: 'table', loading: false});
      dispatch(showError(`Could not get downloads.  ${getErrorMessage(err)}`));
    });
};

export const selectPreset = (history, preset, saveToStorage = true) => dispatch => {
  if (saveToStorage) {
    saveSettingsToStorage(FILTERS_PRESET_ID, preset.value);
    resetLastFiltersInSessionStorage();
  }
  updateAdvancedModeInURL(history, !!preset.is_advanced);
  dispatch({type: AUTO_DOWNLOADS_FILTERS_PRESETS_SELECT, preset});
  dispatch(getEmails(history));
};

export const changePageSize = (value, saveToStorage = true) => dispatch => {
  if (saveToStorage) {
    saveSettingsToStorage('pageSize', value);
  }
  dispatch({type: AUTO_DOWNLOADS_CHANGE_PAGE_SIZE, value});
};

export const changeCurrentPage = page => dispatch => {
  dispatch({type: AUTO_DOWNLOADS_CHANGE_CURRENT_PAGE, page});
};

export const savePreset = (history, presetName, isDefault) => (dispatch, getState) => {
  const {usedFilters, selectedFiltersPreset} = getState().downloadManager;
  dispatch({type: AUTO_DOWNLOADS_SET_LOADING, name: 'savePreset', loading: true});
  const presetId = presetName ? null : (selectedFiltersPreset || {}).value;
  const presetToSave = {
    value: presetId,
    label: presetName || (selectedFiltersPreset || {}).label,
    is_default: typeof isDefault === 'boolean' ? isDefault : (selectedFiltersPreset || {}).is_default,
    is_advanced: window.location.search.indexOf('advanced=1') >= 0,
    filters: usedFilters
  };
  return $.ajax({
    method: 'POST',
    url: `${config.apiUrl}/v1/faspex/filters_presets`,
    contentType: 'application/json;charset=utf-8',
    dataType: 'json',
    data: JSON.stringify(presetToSave)
  })
  .done(storedPresetId => {
    saveSettingsToStorage(FILTERS_PRESET_ID, storedPresetId);
    resetLastFiltersInSessionStorage();
    dispatch({type: AUTO_DOWNLOADS_FILTERS_PRESETS_SAVE, preset: {...presetToSave, value: storedPresetId}, isNew: !presetId});
  })
  .fail(error => {
    dispatch({type: AUTO_DOWNLOADS_SET_LOADING, name: 'savePreset', loading: false});
    dispatch(showError(`Could not save preset.  ${getErrorMessage(error)}`));
  })
  .then(() => {
    if (getState().downloadManager.appliedUsedFiltersJson !== JSON.stringify(getState().downloadManager.usedFilters)) {
      dispatch(getEmails(history));
    }
  });
};

export const checkItemForLinking = item => dispatch => {
  const {file_name} = item;
  dispatch({type: AUTO_DOWNLOADS_SET_LOADING, name: 'linkToMemfisWO', loading: true});
  $.ajax({
    method: 'GET',
    url: `${config.apiUrl}/v1/file_system/link_to_memfis_wo/is_allow`,
    data: {file_name}
  })
  .done(res => {
    dispatch({type: AUTO_DOWNLOADS_SET_LOADING, name: 'linkToMemfisWO', loading: false});
    dispatch(showLinkToMemfisModalForAutoDownloads({...item, ...res}));
  })
  .fail(error => {
    dispatch({type: AUTO_DOWNLOADS_SET_LOADING, name: 'linkToMemfisWO', loading: false});
    dispatch(showError(`Could not get item for linking to WO#.  ${getErrorMessage(error)}`));
  });
};

export const changePasswordForAutoDownloads = (link, password, onSuccess, onError) => dispatch => {
  const {link_id} = link;
  dispatch({type: AUTO_DOWNLOADS_SET_LOADING, name: 'changeLinkField', loading: true});
  $.ajax({
    method: 'PUT',
    url: `${config.apiUrl}/v1/faspex/email_link/${link_id}`,
    data: {password}
  })
  .done(() => {
    dispatch({type: AUTO_DOWNLOADS_CHANGE_LINK_FIELD, item: link, name: 'password', value: password});
    dispatch(showSuccess('Password has been successfully updated.'));
    onSuccess && onSuccess();
  })
  .fail(error => {
    dispatch({type: AUTO_DOWNLOADS_SET_LOADING, name: 'changeLinkField', loading: false});
    dispatch(showError(`Could not change password for aspera link.  ${getErrorMessage(error)}`));
    onError && onError();
  });
};

const saveMemfisWOForAutoDownloads = (linkId, memfiswo) => dispatch => {
  $.ajax({
    method: 'PUT',
    url: `${config.apiUrl}/v1/faspex/email_link/${linkId}`,
    data: {memfiswo}
  })
  .done(() => {
  })
  .fail(error => {
    dispatch(showError(`Could not save WO# for aspera link.  ${getErrorMessage(error)}`));
  });
};

const saveMemfisWOForUploads = (uploadId, memfiswo) => dispatch => {
  $.ajax({
    method: 'PUT',
    url: `${config.apiUrl}/v1/faspex/mam_uploads_link/${uploadId}`,
    data: {wo_memfis: memfiswo}
  })
  .done(() => {
  })
  .fail(error => {
    dispatch(showError(`Could not save WO# for upload link.  ${getErrorMessage(error)}`));
  });
};

export const setMemfisWOForDataItem = (item, memfisWoID, destinationPath) => (dispatch, getState) => {
  const {selectedItems} = getState().downloadManager;
  const isSelectedItem = selectedItems.length === 1 && isEqualsItems(item, selectedItems[0]);
  const {link_id, upload_id} = item;
  if (upload_id) {
    dispatch({type: MAM_UPLOADS_LINK_TO_MEMFIS_WO_IS_SAVED, uploadId: upload_id, memfisWoID, destinationPath, isSelectedItem});
    dispatch(saveMemfisWOForUploads(upload_id, memfisWoID));
  } else {
    dispatch({type: AUTO_DOWNLOADS_LINK_TO_MEMFIS_WO_IS_SAVED, item, memfisWoID, destinationPath, isSelectedItem});
    dispatch(saveMemfisWOForAutoDownloads(link_id, memfisWoID));
  }
};

export const showSelectFolderModal = (item, items) => ({type: AUTO_DOWNLOADS_SHOW_SELECT_FOLDER_MODAL, item, items});

export const closeSelectFolderModal = () => ({type: AUTO_DOWNLOADS_SHOW_SELECT_FOLDER_MODAL});

export const showIngestModal = (isShow, operationTypes, workOrder, folder, item) => ({
  type: DOWNLOAD_MNG_INGEST_SHOW_MODAL, operationTypes, workOrder, show: isShow, folder, item
});

export const getAndShowIngestOperationTypes = (WOMemfis, folder, item) => dispatch => {
  dispatch({type: AUTO_DOWNLOADS_SET_LOADING, name: 'ingestOperationTypes', loading: true});
  return $.ajax({
    method: 'GET',
    url: `${config.apiUrl}/v1/file_system/ingest_operation_types/${WOMemfis}`
  })
  .done(res => {
    const {operation_types, work_order} = res;
    dispatch({type: AUTO_DOWNLOADS_SET_LOADING, name: 'ingestOperationTypes', loading: false});
    dispatch(closeSelectFolderModal());
    dispatch(showIngestModal(true, operation_types || {}, {...work_order, woMemfisId: WOMemfis}, folder, item));
  })
  .fail(error => {
    dispatch({type: AUTO_DOWNLOADS_SET_LOADING, name: 'ingestOperationTypes', loading: false});
    dispatch(showError(`Could not get the ingest operation types.  ${getErrorMessage(error)}`));
  });
};

export const checkManualUpload = folder => (dispatch, getState) => {
  const {selectFolderModalItem, selectFolderModalItems} = getState().downloadManager;
  const {WOMemfis} = selectFolderModalItem;
  const notLinkedItems = (selectFolderModalItems || []).filter(item => !item.file_name);
  if(notLinkedItems.length === 1) {
    dispatch(getAndShowIngestOperationTypes(WOMemfis, folder, selectFolderModalItem));
  } else {
    dispatch(setManualUpload(folder));
  }
};

export const setManualUpload = (folder, ingestData) => (dispatch, getState) => {
  dispatch({type: AUTO_DOWNLOADS_SET_LOADING, name: 'manualUpload', loading: true});
  const {selectFolderModalItem, ingestItem} = getState().downloadManager;
  const {WOMemfis, package_id, link_id, is_parallel} = selectFolderModalItem || ingestItem;
  const {fsRootID, contentID, key} = folder;
  const data = {
    package_id,
    wo_memfis: WOMemfis,
    file_name: key,
    content_id: contentID,
    fs_root_id: fsRootID
  }
  if (ingestData) {
    const operationTypes = ingestData[1].steps.filter(item => item.value).reduce((acc, item) => acc | item.id, 0);
    data.operation_types = operationTypes;
  }
  $.ajax({
    method: 'PUT',
    url: `${config.apiUrl}/v1/faspex/manual_upload`,
    data
  })
  .done(() => {
    dispatch({type: AUTO_DOWNLOADS_SET_LOADING, name: 'manualUpload', loading: false});
    dispatch({type: AUTO_DOWNLOADS_MANUAL_UPLOAD_IS_DONE, WOMemfis, package_id, link_id, is_parallel, folderKey: key});
    dispatch(closeSelectFolderModal());
    dispatch(showIngestModal(false));
  })
  .fail(error => {
    dispatch({type: AUTO_DOWNLOADS_SET_LOADING, name: 'manualUpload', loading: false});
    dispatch(showError(`Could not set manual upload.  ${getErrorMessage(error)}`));
  });
};

export const restartAction = actionId => dispatch => {
  dispatch({type: AUTO_DOWNLOADS_SET_LOADING, name: 'restartAction', loading: true});
  $.ajax({
    method: 'POST',
    url: `${config.apiUrl}/v1/file_system/actions/${actionId}/restart`
  })
  .done(() => {
    dispatch({type: AUTO_DOWNLOADS_SET_LOADING, name: 'restartAction', loading: false});
    dispatch(showSuccess('Action has been successfully restarted.'));
  })
  .fail(error => {
    dispatch({type: AUTO_DOWNLOADS_SET_LOADING, name: 'restartAction', loading: false});
    let errorMsg = getErrorMessage(error);
    if (errorMsg) {
      errorMsg = errorMsg.replace('ErrorMsg:', '');
    } else {
      errorMsg = `Could not restart action!  ${errorMsg}`;
    }
    dispatch(showError(errorMsg));
  });
};

export const showUploadedFiles = files => ({type: MAM_UPLOADS_FILES_SHOW, files});

export const getUploadedFiles = item => dispatch => {
  const {upload_id, WOMemfis, client_name} = item;
  dispatch({type: AUTO_DOWNLOADS_SET_LOADING, name: 'getUploadedFiles', loading: true});
  $.ajax({
    method: 'GET',
    url: `${config.apiUrl}/v1/faspex/mam_uploads_link/${upload_id}/files`
  })
  .done(res => {
    dispatch({type: AUTO_DOWNLOADS_SET_LOADING, name: 'getUploadedFiles', loading: false});
    if (Array.isArray(res)) {
      const files = res.map(file => ({
        ...file,
        WOMemfis,
        client_name,
        file_name: file.mam_path || file.path,
        file_size_bytes: file.size,
        file_size: formatBytes(file.size),
        updated_on_sec: file.updated_on_sec || file.updatedOnSec,
        updated_on: file.updated_on || file.updatedOn
      }));
      dispatch(showUploadedFiles(files));
    } else {
      dispatch(showError('Could not get upload files: Invalid response'));
    }
  })
  .fail(error => {
    dispatch({type: AUTO_DOWNLOADS_SET_LOADING, name: 'getUploadedFiles', loading: false});
    dispatch(showError(`Could not get upload files.  ${getErrorMessage(error)}`));
  });
};

export const completeDownloadForMultipleWOs = item => (dispatch, getState) => {
  const {link_id} = item;
  if (typeof link_id !== 'number') {
    dispatch(showError('typeof link_id !== \'number\'.  Could not complete download for multiple WO-s.'));
    return;
  } else if (link_id <= 0) {
    dispatch(showError('Invalid link_id.  Could not complete download for multiple WO-s.'));
    return;
  }

  const {selectedItems} = getState().downloadManager;
  const isSelectedItem = selectedItems.length === 1 && isEqualsItems(item, selectedItems[0]);

  const loadingName = 'completeDownloadForMultipleWOs';
  dispatch({type: AUTO_DOWNLOADS_SET_LOADING, name: loadingName, loading: true});

  $.ajax({
    method: 'POST',
    url: `${config.apiUrl}/v1/faspex/email_link/${link_id}/mark_final_move_without_wo`
  })
  .done(() => {
    dispatch({type: AUTO_DOWNLOADS_SET_LOADING, name: loadingName, loading: false});
    dispatch({type: AUTO_DOWNLOADS_COMPLETE_DOWNLOAD_FOR_MULTIPLE_WOS, item, isSelectedItem});
    dispatch(closeLinkWOAlreadyLinkedInBurbankModal());
    dispatch(showSuccess('Refreshing is necessary to continue linking WO(s)'));
  })
  .fail(error => {
    dispatch({type: AUTO_DOWNLOADS_SET_LOADING, name: loadingName, loading: false});
    dispatch(showError(`Could not complete download for multiple WO-s.  ${getErrorMessage(error)}`));
  });
};

export const changeTargetRate = (link, newValue, onError) => dispatch => {
  const {link_id} = link;
  dispatch({type: AUTO_DOWNLOADS_SET_LOADING, name: 'changeLinkField', loading: true});
  return $.ajax({
    method: 'PUT',
    url: `${config.apiUrl}/v1/faspex/netflix_download_rate/${link_id}`,
    data: {target_rate_kbps: newValue}
  })
  .done(() => {
    dispatch({type: AUTO_DOWNLOADS_CHANGE_LINK_FIELD, item: link, name: 'target_rate_kbps', value: newValue});
    dispatch(showSuccess('Target rate has been successfully updated.'));
  })
  .fail(error => {
    dispatch({type: AUTO_DOWNLOADS_SET_LOADING, name: 'changeLinkField', loading: false});
    dispatch(showError(`Could not change target rate.  ${getErrorMessage(error)}`));
    onError && onError();
  });
};

export const markCompleted = link => dispatch => {
  const loadingName = 'changeLinkField';
  dispatch({type: AUTO_DOWNLOADS_SET_LOADING, name: loadingName, loading: true});
  return $.ajax({
    method: 'POST',
    url: `${config.apiUrl}/v1/faspex/mark_completed`,
    data: {
      id: [link.link_id]
    }
  })
  .done(() => {
    dispatch({type: AUTO_DOWNLOADS_CHANGE_LINK_FIELD, item: link, name: 'status', value: "COMPLETED"});
    dispatch(showSuccess('Completed successfully!'));
  })
  .fail(error => {
    dispatch(showError(`Could not mark completed.  ${getErrorMessage(error)}`));
  })
  .always(() => {
    dispatch({type: AUTO_DOWNLOADS_SET_LOADING, name: loadingName, loading: false});
  });
};

export const changePriorityForAutoDownloads = (link, priority, onSuccess) => dispatch => {
  const {requestID, link_id} = link;
  const parameters = {
    REQUEST_ID: requestID,
    PRIORITY: priority
  };
  if (link_id !== undefined) {
    parameters.ASPERA_LINK_ID = link_id;
  }
  dispatch({type: AUTO_DOWNLOADS_SET_LOADING, name: 'changeLinkField', loading: true});
  $.ajax({
    method: 'POST',
    url: `${config.apiUrl}/v1/file_system/action_request`,
    contentType: 'application/json;charset=utf-8',
    dataType: 'json',
    data: JSON.stringify({
      actionType: FS_ACTION_TYPE__SET_DOWNLOAD_PRIORITY,
      parameters
    })
  })
  .done(() => {
    dispatch({type: AUTO_DOWNLOADS_CHANGE_LINK_FIELD, item: link, name: 'Priority', value: priority});
    dispatch(showSuccess('Priority has been successfully updated.'));
    onSuccess && onSuccess();
  })
  .fail(error => {
    dispatch({type: AUTO_DOWNLOADS_SET_LOADING, name: 'changeLinkField', loading: false});
    dispatch(showError(`Could not change priority.  ${getErrorMessage(error)}`));
  });
};

const showDeliveryModal = woMemfisID => dispatch => {
  dispatch({type: STORAGE_MNG_SET_CURRENT_VIEW, view: VIEW_ADVANCED_PREDICTIVE});
  dispatch({type: DOWNLOAD_MNG_SHOW_DELIVERY_MODAL, woMemfisID});
};

export const closeDeliveryModal = () => ({type: DOWNLOAD_MNG_SHOW_DELIVERY_MODAL});

export const processDelivery = (history, deliveryMemfisWO, redirect, actionId) => dispatch => {
  if (actionId && [DELIVERY_PAGE__EDIT, DELIVERY_PAGE__APPROVE].includes(redirect)) {
    history.push(`/delivery/${redirect}/${actionId}`);
  } else {
    dispatch(showDeliveryModal(deliveryMemfisWO));
  }
};

export const startDeliveryMemfisWO = (history, woMemfisID, folder) => dispatch => {
  dispatch({type: AUTO_DOWNLOADS_SET_LOADING, name: 'startDeliveryMemfisWO', loading: true});
  const {fsRootID, contentID, key} = folder;
  $.ajax({
    method: 'POST',
    url: `${config.apiUrl}/v1/file_system/action_request`,
    contentType: 'application/json;charset=utf-8',
    dataType: 'json',
    data: JSON.stringify({
      actionType: FS_ACTION_TYPE__DELIVERY_MEMFIS_WO,
      fsRootID,
      parameters: {
        ContentIDs: [String(contentID)],
        DESTINATION: key,
        WO_MEMFIS: woMemfisID
      }
    })
  })
  .done(res => {
    dispatch({type: AUTO_DOWNLOADS_SET_LOADING, name: 'startDeliveryMemfisWO', loading: false});
    dispatch(closeDeliveryModal());
    const {redirect} = res;
    const actionID = res.ActionID || res.actionID;
    if (actionID && [DELIVERY_PAGE__EDIT, DELIVERY_PAGE__APPROVE, DELIVERY_PAGE__VIEW].includes(redirect)) {
      if (redirect !== DELIVERY_PAGE__VIEW) {
        history.push(`/delivery/${redirect}/${actionID}`);
      } else {
        dispatch(showInfo('Delivery is not possible now'));
      }
    } else {
      dispatch(showError(`Could not delivery.  Invalid response: ${JSON.stringify(res)}`));
    }
  })
  .fail(error => {
    dispatch({type: AUTO_DOWNLOADS_SET_LOADING, name: 'startDeliveryMemfisWO', loading: false});
    dispatch(showError(`Could not delivery.  ${getErrorMessage(error)}`));
  });
};

export const getActionRequestors = () => (dispatch) => {
  return $.ajax({
    method: 'GET',
    url: `${config.apiUrl}/v1/file_system/actions_requestors`
  })
  .done(res => {
    const actionsRequestorsMap = {};
    (res.requestors || []).forEach(r => actionsRequestorsMap[r.value] = r.name);

    dispatch({
      type: STORAGE_MNG_ACTIONS_REQUESTORS_SHOW,
      actionsRequestorsMap
    });
  })
  .fail(error => {

  });
}

export const changeAsperaLinkFileSize = (link, fileSizeBytes) => dispatch => {
  const {link_id} = link;
  dispatch({type: AUTO_DOWNLOADS_SET_LOADING, name: 'changeLinkField', loading: true});
  return $.ajax({
    method: 'POST',
    url: `${config.apiUrl}/v1/faspex/aspera_link/${link_id}/file_size`,
    data: {file_size: fileSizeBytes}
  })
  .done(() => {
    dispatch({type: AUTO_DOWNLOADS_CHANGE_LINK_FIELD, item: link, name: 'file_size', value: formatBytes(fileSizeBytes)});
    dispatch(showSuccess('File size has been successfully updated.'));
  })
  .fail(error => {
    dispatch({type: AUTO_DOWNLOADS_SET_LOADING, name: 'changeLinkField', loading: false});
    dispatch(showError(`Could not change file size.  ${getErrorMessage(error)}`));
  });
};

export const stopOrRestartAsperaLink = (linkId, actionName) => dispatch => {
  dispatch({type: AUTO_DOWNLOADS_SET_LOADING, name: 'changeLinkField', loading: true});
  return $.ajax({
    method: 'POST',
    url: `${config.apiUrl}/v1/faspex/aspera_link/${linkId}/action`,
    data: {action_name: actionName}
  })
  .done(() => {
    dispatch({type: AUTO_DOWNLOADS_SET_LOADING, name: 'changeLinkField', loading: false});
    dispatch(showSuccess('Aspera download has been successfully updated.'));
  })
  .fail(error => {
    dispatch({type: AUTO_DOWNLOADS_SET_LOADING, name: 'changeLinkField', loading: false});
    dispatch(showError(`Could not change status of Aspera download.  ${getErrorMessage(error)}`));
  });
};

export const refreshAsperaLinkInfo = (linkItem) => dispatch => {
  dispatch({type: AUTO_DOWNLOADS_SET_LOADING, name: 'changeLinkField', loading: true});
  return $.ajax({
    method: 'GET',
    url: `${config.apiUrl}/v1/faspex/emails_new`,
    data: {aspera_link_id: linkItem.link_id}
  })
  .done((res) => {
    dispatch({type: AUTO_DOWNLOADS_SET_LOADING, name: 'changeLinkField', loading: false});
    if (res.data) {
      const newLinkItem = res.data.find(x => x.requestID === linkItem.requestID);
      dispatch({type: AUTO_DOWNLOADS_GET_EMAILS_UPDATE_LINK, newLink: newLinkItem});
    }
    dispatch(showSuccess('Aspera download has been successfully updated.'));
  })
  .fail(error => {
    dispatch({type: AUTO_DOWNLOADS_SET_LOADING, name: 'changeLinkField', loading: false});
    dispatch(showError(`Could not change status of Aspera download.  ${getErrorMessage(error)}`));
  });
};
