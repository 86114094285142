import _ from 'lodash';

export const DISPLAY_CATEGORY_ROOTS = 'DISPLAY_CATEGORY_ROOTS';
export const DISPLAY_CATEGORY_FOLDER = 'DISPLAY_CATEGORY_FOLDER';
export const DISPLAY_CATEGORY_SEARCH_RESULTS = 'DISPLAY_CATEGORY_SEARCH_RESULTS';
export const DISPLAY_CATEGORY_ACTION_CONTENTS = 'DISPLAY_CATEGORY_ACTION_CONTENTS';
export const DISPLAY_CATEGORY_INIT_CONFIRM_DELETION = 'DISPLAY_CATEGORY_INIT_CONFIRM_DELETION';

export const SEARCH_IN_OPTION_CURRENT_FOLDER = 'current_folder';
export const SEARCH_IN_OPTION_SELECTIONED_ITEMS = 'selectioned_items';
export const SEARCH_IN_FILTER_NAME = 'search_in';
export const NAME_FILTER_NAME = 'name';
export const SIZE_FILTER_NAME = 'size';
export const LAST_MODIFIED_DATE_FILTER_NAME = 'date';
export const USE_MAX_MODIFIED_ON_FILTER_NAME = 'use_max_modified_on';
export const USE_PREDICTIVE_FILTER_NAME = 'use_predictive';
export const FILENAME_AS_WELL_FILTER_NAME = 'filename_as_well';
export const DELETED_AS_WELL_FILTER_NAME = 'deleted_as_well';
export const LAST_SEGMENT_ONLY_FILTER_NAME = 'last_segment_only';
export const WO_MEMFIS_FILTER_NAME = 'wo_memfis';
export const WITH_FILE_SYMLINKS_FILTER_NAME = 'with_file_symlinks';
export const SYMLINKS_TOO_FILTER_NAME = 'symlinks_too';

export const ALL_FILTERS =
[{
  name: SEARCH_IN_FILTER_NAME,
  title: 'Search in',
  type: 'radio',
  values: [{
    name: 'Current Folder',
    value: SEARCH_IN_OPTION_CURRENT_FOLDER
  }, {
    name: 'Selected item(s)',
    value: SEARCH_IN_OPTION_SELECTIONED_ITEMS
  }]
}, {
  name: NAME_FILTER_NAME,
  title: 'Name',
  type: 'string'
}, {
  name: SIZE_FILTER_NAME,
  title: 'Size',
  type: 'size'
}, {
  name: LAST_MODIFIED_DATE_FILTER_NAME,
  title: 'Modified On',
  type: 'date'
}, {
  name: USE_MAX_MODIFIED_ON_FILTER_NAME,
  title: 'Use MaxModified',
  type: 'bool',
  defaultValueAlt: true,
}, {
  name: FILENAME_AS_WELL_FILTER_NAME,
  title: 'Search Filenames',
  type: 'bool'
}, {
  name: DELETED_AS_WELL_FILTER_NAME,
  title: 'Search Deleted Space',
  type: 'bool'
}, {
  name: LAST_SEGMENT_ONLY_FILTER_NAME,
  title: 'Search last segment only',
  type: 'bool',
  defaultValueAlt: true,
}, {
  name: SYMLINKS_TOO_FILTER_NAME,
  title: 'Search symlinks too',
  type: 'bool',
  defaultValueAlt: true,
}, {
  name: WO_MEMFIS_FILTER_NAME,
  title: 'WO#',
  type: 'string'
}];

export const ALL_BOOLEAN_FILTER_NAMES = ALL_FILTERS.filter(({type}) => type === 'bool').map(({name}) => name);

export const DEFAULT_BOOLEAN_FILTER_NAMES = ALL_FILTERS.filter(({type, defaultValueAlt}) => type === 'bool' && defaultValueAlt).map(({name}) => name);

export const filterNamesInString = [
  'name', 'sizemin', 'sizemax', 'modifiedfrom', 'modifiedto',
  ...ALL_BOOLEAN_FILTER_NAMES,
  WO_MEMFIS_FILTER_NAME
];

export const filterNamesMap = {
  [NAME_FILTER_NAME]: 'name',
  [SIZE_FILTER_NAME]: 'size',
  [LAST_MODIFIED_DATE_FILTER_NAME]: 'modified'
};

export const DefaultBooleanFilters = DEFAULT_BOOLEAN_FILTER_NAMES.reduce((a, name) => ({...a, [name]: true}), {});

export const getDefaultBooleanFiltersFromUsedFilters = usedFilters => {
  return DEFAULT_BOOLEAN_FILTER_NAMES.reduce((a, name) => ({...a, [name]: usedFilters[name]}), {});
};

/* Possible values of fsRootFlags property. */
export const FsRootFlags = {
  IS_ACTIVE: 1,
  DOWNLOAD_ROOT: 2,
  DEFAULT_FS_ROOT_FOR_OFFICE: 4,
  READ_ONLY: 8,
  VISIBLE_FROM_PRD: 16,
  IS_FAKE: 32,
};

export const VIEW_DEFAULT = 'VIEW_DEFAULT';
export const VIEW_ADVANCED_SIMPLE = 'VIEW_ADVANCED_SIMPLE';
export const VIEW_ADVANCED_PREDICTIVE = 'VIEW_ADVANCED_PREDICTIVE';

export const ALL_VIEWS = [{
  value: VIEW_DEFAULT,
  label: 'Default'
}, {
  value: VIEW_ADVANCED_SIMPLE,
  label: 'Advanced Simple'
}, {
  value: VIEW_ADVANCED_PREDICTIVE,
  label: 'Advanced Predictive'
}];

export const PREDICTIVE_STATE_DISAPPEARED = 1;
export const PREDICTIVE_STATE_APPEARED = 2;
export const DEFAULT_MAX_CONTENT_COUNT_TO_MIGRATE = 500;

export const filterDataForView = (data, view) => {
  if (view === VIEW_ADVANCED_SIMPLE) {
    const newData = [...data];
    const removedData = _.remove(newData, item => Boolean(item.predictiveStateID));
    return [newData, removedData];
  } else if (view === VIEW_DEFAULT) {
    const newData = [...data];
    const removedData = _.remove(newData, item => item.predictiveStateID === PREDICTIVE_STATE_DISAPPEARED);
    return [newData, removedData];
  }
  return [data, null];
};

export const RESULT_TYPE_WITHOUT_PREDICTIVE = 'WITHOUT_PREDICTIVE';

export const FS_ACTION_TYPE__MK_DIRECTORY = 'MK_DIRECTORY';
export const FS_ACTION_TYPE__INSTANT_SCAN = 'INSTANT_SCAN_NEW';
export const FS_ACTION_TYPE__MOVE = 'MOVE';
export const FS_ACTION_TYPE__MULTIPLE_MOVE = 'MULTIPLE_MOVE';
export const FS_ACTION_TYPE__MIGRATE_PORTAL = 'MIGRATE_CONTENT_PORTAL';
export const FS_ACTION_TYPE__READ_FILE = 'READ_FILE';
export const FS_ACTION_TYPE__ZIP_CONTENT = 'ZIP_CONTENT';
export const FS_ACTION_TYPE__GET_APLUS_SETTINGS = 'GET_APLUS_SETTINGS';
export const FS_ACTION_TYPE__SET_APLUS_SETTINGS = 'SET_APLUS_SETTINGS';
export const FS_ACTION_TYPE__SET_DOWNLOAD_PRIORITY = 'SET_DOWNLOAD_PRIORITY';
export const FS_ACTION_TYPE__CONNECT_DRIVE = 'CONNECT_DRIVE';
export const FS_ACTION_TYPE__DISCONNECT_DRIVE = 'DISCONNECT_DRIVE';
export const FS_ACTION_TYPE__DELETE_WITH_APPROVAL = 'DELETE_WITH_APPROVAL';
export const FS_ACTION_TYPE__ENCRYPT_CONTENT = 'ENCRYPT_CONTENT';
export const FS_ACTION_TYPE__DECRYPT_CONTENT = 'DECRYPT_CONTENT';
export const FS_ACTION_TYPE__DELIVERY_MEMFIS_WO = 'DELIVERY_MEMFIS_WO';
export const FS_ACTION_TYPE__SIMPLE_COPY = 'SIMPLE_COPY';
export const FS_ACTION_TYPE__COPY = 'COPY';
export const FS_ACTION_TYPE__PROXY_COPY = 'PROXY_COPY';
export const FS_ACTION_TYPE__GENERAL_PROXY_COPY = 'GENERAL_PROXY_COPY';
export const FS_ACTION_TYPE__START_SCRIPT_RUNNER = 'START_SCRIPT_RUNNER';
export const FS_ACTION_TYPE__STOP_SCRIPT_RUNNER = 'STOP_SCRIPT_RUNNER';
export const FS_ACTION_TYPE__STOP_FILE_SYSTEM_RUNNERS = 'STOP_FILE_SYSTEM_RUNNERS';
export const FS_ACTION_TYPE__START_FILE_SYSTEM_RUNNERS = 'START_FILE_SYSTEM_RUNNERS';
export const FS_ACTION_TYPE__START_MACHINE_RUNNERS = 'START_MACHINE_RUNNERS';
export const FS_ACTION_TYPE__STOP_MACHINE_RUNNERS = 'STOP_MACHINE_RUNNERS';
export const FS_ACTION_TYPE__START_DAT_SERVICE_RUNNER = 'START_DAT_SERVICE_RUNNER';
export const FS_ACTION_TYPE__STOP_DAT_SERVICE_RUNNER = 'STOP_DAT_SERVICE_RUNNER';
export const FS_ACTION_TYPE__SET_CONTENT_PERMISSIONS_PORTAL = 'SET_CONTENT_PERMISSIONS_PORTAL';
export const FS_ACTION_TYPE__SET_CONTENT_GROUP_PORTAL = 'SET_CONTENT_GROUP_PORTAL';
export const FS_ACTION_TYPE__START_OFFICE_SERVICE_RUNNERS = 'START_OFFICE_SERVICE_RUNNERS';
export const FS_ACTION_TYPE__STOP_OFFICE_SERVICE_RUNNERS = 'STOP_OFFICE_SERVICE_RUNNERS';
export const FS_ACTION_TYPE__LKFS_ANALYSIS_PORTAL = 'LKFS_ANALYSIS_PORTAL';
export const FS_ACTION_TYPE__MD5_CHECKSUM = 'MD5_CHECKSUM';
export const FS_ACTION_TYPE__SHA1_CHECKSUM = 'SHA1_CHECKSUM';
export const FS_ACTION_TYPE__ADD_TO_MEMFIS_WO = 'ADD_TO_MEMFIS_WO';
export const FS_ACTION_TYPE__ATTACH_FILE_QOD = 'ATTACH_FILE_QOD';
export const FS_ACTION_TYPE__DEMUX = 'DEMUX_PORTAL';
export const FS_ACTION_TYPE__PSE_ANALYSIS_PORTAL = 'PSE_ANALYSIS_PORTAL';
export const FS_ACTION_TYPE__UPLOAD_TO_ASPERA = 'UPLOAD_ASPERA_PACKAGE_PORTAL';
export const FS_ACTION_TYPE__UPLOAD_CONTENT = 'UPLOAD_CONTENT';
export const FS_ACTION_TYPE__CREATE_SYMLINK_PORTAL = 'CREATE_SYMLINK_PORTAL';
export const FS_ACTION_TYPE__CHECKSUM_REPORT = 'CHECKSUM_REPORT_PORTAL';
export const FS_ACTION_TYPE__LINK_TO_MEMFIS_WO = 'LINK_TO_MEMFIS_WO';
export const FS_ACTION_TYPE__DELETE_SYMLINK_PORTAL = 'DELETE_SYMLINK_PORTAL';
export const FS_ACTION_TYPE__RECONCILE_SYMLINK_PORTAL = 'RECONCILE_SYMLINK_PORTAL';
export const FS_ACTION_TYPE__RENAME_SPEC_SYMBOLS_PORTAL = 'RENAME_SPEC_SYMBOLS_PORTAL';
export const FS_ACTION_TYPE__DELIVERY_RENAME_PORTAL = 'DELIVERY_RENAME_PORTAL';
export const FS_ACTION_TYPE__INTEROFFICE_COPY_PORTAL = 'INTEROFFICE_COPY_PORTAL';
export const FS_ACTION_TYPE__CAPTIONS = 'CONVERT_CAPTIONS';
export const FS_ACTION_TYPE__METAFIER_DOLBY_CREATE_XML = 'METAFIER_DOLBY_CREATE_XML';
export const FS_ACTION_TYPE__EDGE_PASS_PORTAL = 'EDGE_PASS_PORTAL';
export const FS_ACTION_TYPE__SCREEN_TO_QOD_PORTAL = 'SCREEN_TO_QOD_PORTAL';
export const FS_ACTION_TYPE__HDR_REPORT_PORTAL = 'HDR_REPORT_PORTAL';
export const FS_ACTION_TYPE__XXH_CHECKSUM = 'XXH_CHECKSUM';

export const SYMLINK_RELATIVE_STATUS__LIVE = 'LIVE';
export const SYMLINK_RELATIVE_STATUS__SOURCE_DELETED = 'SOURCE_DELETED';
export const SYMLINK_RELATIVE_STATUS__SOURCE_PATH_CHANGED = 'SOURCE_PATH_CHANGED';
export const SYMLINK_RELATIVE_STATUS__RELATIVE_PATH_CHANGED = 'RELATIVE_PATH_CHANGED';
export const SYMLINK_RELATIVE_STATUS__SOURCE_NOT_FOUND = 'SOURCE_NOT_FOUND';

export const isSymlinkLive = ({relativeStatus}) => relativeStatus === SYMLINK_RELATIVE_STATUS__LIVE;
export const isSymlinkDeleted = ({relativeStatus}) => relativeStatus === SYMLINK_RELATIVE_STATUS__SOURCE_DELETED;
export const isSymlinkSimple = ({relativeStatus}) => [
    SYMLINK_RELATIVE_STATUS__SOURCE_PATH_CHANGED,
    SYMLINK_RELATIVE_STATUS__RELATIVE_PATH_CHANGED,
    SYMLINK_RELATIVE_STATUS__SOURCE_NOT_FOUND
  ].includes(relativeStatus);

export const PATH_VIEW_DOS = 'PATH_VIEW_DOS';
export const PATH_VIEW_MAC_OS = 'PATH_VIEW_MAC_OS';
