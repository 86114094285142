import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {
  Modal, FormGroup, FormControl, ControlLabel, InputGroup, Checkbox, Button, OverlayTrigger, Tooltip, Collapse
} from 'react-bootstrap';
import styled from 'styled-components';
import {
  closeChecksumReportModal,
  getAvailableActionsBySources,
  createChecksumReport,
  setFoldersInModal,
  getAttachmentFilters
} from '../../actions';
import FolderExplorerNew from '../forms/FolderExplorerNew';
import TextEllipsisAdvanced from '../../../../components/TextEllipsis/TextEllipsisAdvanced';
import {FS_ACTION_TYPE__CHECKSUM_REPORT} from '../../constants';
import {getValueForOS, preparePathForMacOS} from '../../utils';
import Select from 'react-select';
import {isValidFileName} from '../../../../utils';
import moment from 'moment';
import RndModal from '../../../../components/modals/RndModal';
import WOChooser from "../../../../components/WOChooser/WOChooser";
import {CATEGORY_PSE_TEST} from "./AttachToWoModal";
import HelpCircleIcon from "../../../../components/icons/HelpCircleIcon";

const CHECKSUM_TYPE__SHA1 = 'SHA1';
const CHECKSUM_TYPE__MD5 = 'MD5';
const CHECKSUM_TYPE__XXHASH = 'XXH';

const CHECKSUM_TYPE__OPTIONS = [CHECKSUM_TYPE__SHA1, CHECKSUM_TYPE__MD5, CHECKSUM_TYPE__XXHASH].map(value => ({value, label: value}));

class ChecksumReportModal extends Component {
  state = {
    preselectedDestFolder: null,
    selectedDestFolder: null,
    originalReportName: '',
    reportName: '',
    reportNameExt: CHECKSUM_TYPE__SHA1,
    reportParentDir: {},
    checksumType: CHECKSUM_TYPE__SHA1,
    oldestChecksumDate: '',
    isRecalculate: false,
    isDisabledRecalculate: false,
    isHiddenFolderExplorer: true,
    enableSendToQod: false,
    isHiddenSelectWoMemfis: true,
    attachmentWoMemfis: null,
    attachmentFilters: null,
    attachmentLabel: null,
    attachmentCategory: null,
    attachmentPseTestStatus: null
  };

  handleSelectChecksumType = (checksumType) => {
    const {storageManager: {availableActionsBySources, fsRoots}, items} = this.props;
    const data = availableActionsBySources[FS_ACTION_TYPE__CHECKSUM_REPORT];
    const {report_file_name, report_file_name_extension, report_parent_dir, report_parent_dir_id} = data;
    const {is_recalculate, oldest_checksum_date} = data[checksumType];

    let reportNameExt = report_file_name_extension || CHECKSUM_TYPE__SHA1;
    if (!report_file_name_extension && items.length > 0) {
      reportNameExt = '.' + checksumType.toLowerCase();
    }

    this.setState({
      preselectedDestFolder: null,
      originalReportName: report_file_name || '',
      reportName: report_file_name || '',
      reportNameExt: reportNameExt,
      reportParentDir: {
        macOsFor: {
          key: preparePathForMacOS(report_parent_dir, fsRoots.find(i => i.fsRootID === items[0].fsRootID))
        },
        key: report_parent_dir,
        contentID: report_parent_dir_id,
        fsRootID: items[0].fsRootID,
        isDirectory: true
      },
      checksumType,
      oldestChecksumDate: oldest_checksum_date || '',
      isRecalculate: is_recalculate,
      isDisabledRecalculate: is_recalculate
    });
  };

  componentDidMount() {
    const {dispatch, items, storageManager: {fsRoots}} = this.props;
    const {checksumType} = this.state;
    const onSelect = () => this.handleSelectChecksumType(checksumType);
    if (items.length === 1) {
      dispatch(getAvailableActionsBySources(items, FS_ACTION_TYPE__CHECKSUM_REPORT, onSelect));
    } else {
      onSelect();
    }

    const {fsRootID} = items[0];
    const folders = fsRoots.filter(i => i.fsRootID === fsRootID).map(i => ({...i}));
    dispatch(setFoldersInModal(folders));
  }

  handleClose = () => {
    const {dispatch} = this.props;
    dispatch(closeChecksumReportModal());
  };

  getReportParentDir = () => {
    const {selectedDestFolder, reportParentDir} = this.state;
    return selectedDestFolder || reportParentDir;
  };

  buildReportFileName = () => {
    const {reportName, reportNameExt} = this.state;
    return `${reportName}${reportNameExt}`;
  };

  handleChangeReportName = ({target: {value}}) => {
    this.setState({
      reportName: value
    });
  };

  handleConfirmCreateChecksumReport = () => {
    const {dispatch, items} = this.props;
    const {checksumType, isRecalculate, enableSendToQod, attachmentWoMemfis, attachmentLabel, attachmentCategory, attachmentPseTestStatus} = this.state;

    if (enableSendToQod && (attachmentWoMemfis === null || attachmentLabel === null || attachmentCategory === null)) {
      alert('WO #, Attachment Label and Category are required fields for the "Send file to QOD" feature');
      return;
    }

    const destination = this.getReportParentDir();
    const reportFileName = this.buildReportFileName();
    const attachmentQodData = enableSendToQod && {
      wo_memfis: attachmentWoMemfis,
      label: attachmentLabel,
      category: attachmentCategory,
      pse_test_status: attachmentPseTestStatus
    }
    dispatch(createChecksumReport(items, destination, reportFileName, checksumType, isRecalculate, attachmentQodData));
  };

  handleConfirmSelectedDestination = () => {
    const {preselectedDestFolder} = this.state;
    this.setState({
      isHiddenFolderExplorer: true,
      selectedDestFolder: preselectedDestFolder
    });
  };

  handleCloseSelectingDestination = () => {
    const {selectedDestFolder} = this.state;
    this.setState({
      isHiddenFolderExplorer: true,
      preselectedDestFolder: selectedDestFolder
    });
  };

  handleCloseWoChooser = () => {
    this.setState({
      isHiddenSelectWoMemfis: true
    });
  };

  handleChangeSendToQod = ({target: {checked}}) => {
    const { dispatch, items } = this.props;
    const itemWoMemfis = items[0].memfisWoID;
    this.setState({
      enableSendToQod: checked,
      attachmentWoMemfis: itemWoMemfis
    });

    dispatch(getAttachmentFilters(itemWoMemfis)).then(
        res => {
          this.setState({
            attachmentFilters: res.filters,
            attachmentCategory: res.filters.attachment_categories.default || null
          });
        }
    ).catch(e => {
      alert('Failed get attachment categories for the "Send file to QOD" feature');
    });
  };

  handleOpenAttachmentCategoryMenu = () => {
    document.querySelector('.modal-body').scrollTo(0, 999)
  };

  getAttachmentDataControls = () => {
    const { enableSendToQod, attachmentFilters, attachmentWoMemfis, attachmentLabel, attachmentCategory, attachmentPseTestStatus } = this.state;

    if (enableSendToQod === false || attachmentFilters === null) {
      return null;
    }

    const isPSETestCategory = attachmentCategory === CATEGORY_PSE_TEST;

    return (
        <>
          <FormGroupStyled>
            <ControlLabel>
              WO #
            </ControlLabel>
            <FormControl.Static componentClass="div">
              {attachmentWoMemfis}
              <Button
                  bsSize="small"
                  className={attachmentWoMemfis ? 'ml15' : undefined}
                  onClick={() => {
                    this.setState({
                      isHiddenSelectWoMemfis: false
                    });
                  }}
              >
                {`${attachmentWoMemfis ? 'Change' : 'Select'}...`}
              </Button>
            </FormControl.Static>
          </FormGroupStyled>
          <FormGroupStyled>
            <ControlLabel>
              Attachment Label
            </ControlLabel>
            <FormControl
                type="text"
                value={attachmentLabel || ''}
                onChange={({target: {value}}) => this.setState({attachmentLabel: value})}
                onBlur={({target: {value}}) => this.setState({attachmentLabel: value.trim()})}
                maxLength={50}
            />
          </FormGroupStyled>
          <FormGroupStyled
              validationState={attachmentFilters && !attachmentCategory ? 'error' : null}
          >
            <ControlLabel>
              Attachment Category
            </ControlLabel>
            <FormControl.Static componentClass="div">
              <Select
                  value={attachmentCategory}
                  options={!attachmentFilters ? [] : attachmentFilters.attachment_categories.options.map(o => ({...o, label: o.name}))}
                  onChange={o => {
                    if (o && o.value !== attachmentCategory) {
                      this.setState({
                        attachmentCategory: o.value,
                        attachmentPseTestStatus: null
                      });
                    }
                  }}
                  clearable={false}
                  disabled={!attachmentFilters}
                  onOpen={this.handleOpenAttachmentCategoryMenu}
              />
            </FormControl.Static>
          </FormGroupStyled>
          <Collapse in={isPSETestCategory}>
            <div>
              <FormGroup
                  validationState={isPSETestCategory && !attachmentPseTestStatus ? 'error' : null}
              >
                <ControlLabel>
                  PSE Test Status
                </ControlLabel>
                <FormControl.Static componentClass="div">
                  <Select
                      value={attachmentPseTestStatus}
                      options={!attachmentFilters ? [] : attachmentFilters.attachment_pse_test.map(o => ({value: o, label: o}))}
                      onChange={o => !!o && this.setState({attachmentPseTestStatus: o.value})}
                      clearable={false}
                      disabled={!attachmentFilters}
                  />
                </FormControl.Static>
              </FormGroup>
            </div>
          </Collapse>
        </>
    );
  };

  getSelectWoMemfisControl = () => {
    const { dispatch } = this.props;
    const { isHiddenSelectWoMemfis, attachmentWoMemfis } = this.state;

    if (isHiddenSelectWoMemfis) {
      return null;
    }

    return (
        <WOChooser
            fsRootID={ null }
            onChoose={(value) => {
              if (value !== attachmentWoMemfis) {
                this.setState({
                  attachmentWoMemfis: value,
                  attachmentLabel: null,
                  attachmentCategory: null,
                  attachmentPseTestStatus: null,
                  attachmentFilters: null,
                });
                dispatch(getAttachmentFilters(value)).then(
                    res => {
                      this.setState({
                        attachmentFilters: res.filters,
                        attachmentCategory: res.filters.attachment_categories.default || null
                      });
                    }
                );
              }
              this.handleCloseWoChooser();
            }}
        />
    );
  }

  render() {
    const {dispatch, storageManager, items} = this.props;
    const {loaders, foldersInModal, openedFoldersInModal, currentView, tempDirectories} = storageManager;

    const {
      preselectedDestFolder, selectedDestFolder, reportName, reportNameExt, checksumType, oldestChecksumDate,
      isRecalculate, isDisabledRecalculate, isHiddenFolderExplorer, originalReportName, enableSendToQod, isHiddenSelectWoMemfis
    } = this.state;

    const isInvalidReportName = originalReportName !== reportName && !isValidFileName(reportName);

    const bsSize = isHiddenSelectWoMemfis ? null : 'largest';

    const tooltipElm = (
      <OverlayTrigger
        placement="right"
        overlay={
          <Tooltip>You can send the generated file with checksum as an attachment to the QOD work order</Tooltip>
        }
      >
        <HelpCircleIcon className="help-tooltip" onClick={e => e.preventDefault()}/>
      </OverlayTrigger>
    );

    return (
      <RndModal
        className="ch-r-modal"
        show={true}
        onHide={this.handleClose}
        backdrop="static"
        bsSize={bsSize}
        enforceFocus={false}
        >
        <Modal.Header closeButton>
          <Modal.Title>
            Create checksum report
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ModalBodyContainer className={`container-fluid ${isHiddenSelectWoMemfis ? '' : 'select-wo'}`}>
            {isHiddenFolderExplorer && isHiddenSelectWoMemfis &&
              <>
                <FormGroupStyled>
                  <ControlLabel>
                    Checksum Type:
                  </ControlLabel>
                  <Select
                    className="shift-left"
                    value={checksumType}
                    options={CHECKSUM_TYPE__OPTIONS}
                    onChange={o => !!o && this.handleSelectChecksumType(o.value)}
                    searchable={false}
                    clearable={false}
                  />
                  {!!oldestChecksumDate &&
                  <OverlayTrigger
                      placement="bottom"
                      overlay={<Tooltip id="oldestChecksumDate">Oldest Checksum Date</Tooltip>}
                  >
                    <div className="shift-left">
                      Oldest Checksum Date: {moment(oldestChecksumDate).format("MM/DD/YYYY hh:mm:ss")}
                    </div>
                  </OverlayTrigger>
                  }

                </FormGroupStyled>
                <FormGroupStyled>
                  <Checkbox
                      className="shift-left"
                      disabled={isDisabledRecalculate}
                      checked={isRecalculate}
                      onChange={({target: {checked}}) => this.setState({isRecalculate: checked})}
                  >
                    Recalculate
                  </Checkbox>
                </FormGroupStyled>
                <FormGroupStyled>
                  <ControlLabel>
                    Destination:
                  </ControlLabel>
                  <TextEllipsisAdvanced className="destination-dir" data={getValueForOS(this.getReportParentDir(), 'key')}/>
                  <Button
                    className="btn-in-field"
                    title="Change Destination..."
                    onClick={() => this.setState({isHiddenFolderExplorer: false})}
                  >
                    <i className="far fa-folder-tree"/>
                  </Button>
                  <Button
                    className="btn-in-field"
                    title="Reset"
                    onClick={() => this.setState({selectedDestFolder: null, preselectedDestFolder: null})}
                    disabled={!selectedDestFolder}
                  >
                    <i className="fa-light fa-folder-xmark"/>
                  </Button>
                </FormGroupStyled>
                <FormGroupStyled validationState={isInvalidReportName ? 'error' : null}>
                  <ControlLabel>
                    Report Name:
                  </ControlLabel>
                  <InputGroup>
                    <FormControl
                      value={reportName}
                      onChange={this.handleChangeReportName}
                      onBlur={this.handleChangeReportName}
                      maxLength={100}
                    />
                    <InputGroup.Addon>{reportNameExt}</InputGroup.Addon>
                  </InputGroup>
                  <Button
                    className="btn-in-field"
                    title="Reset"
                    onClick={() => this.setState({reportName: originalReportName})}
                    disabled={originalReportName === reportName}
                  >
                    <i className="fa-light fa-file-xmark"/>
                  </Button>
                </FormGroupStyled>
                <FormGroupStyled>
                  <Checkbox
                      className="shift-left"
                      checked={enableSendToQod}
                      onChange={this.handleChangeSendToQod}
                  >
                    Send to QOD
                    { tooltipElm }
                  </Checkbox>
                </FormGroupStyled>
                { this.getAttachmentDataControls() }
              </>
            }
            <FolderExplorerNew
              isHidden={isHiddenFolderExplorer}
              dispatch={dispatch}
              contentItems={items}
              loaders={loaders}
              folders={foldersInModal}
              openedFolders={openedFoldersInModal}
              currentView={currentView}
              tempDirectories={tempDirectories}
              selectedFolder={preselectedDestFolder}
              onSelectFolder={selFolder => this.setState({preselectedDestFolder: selFolder})}
              selectedFolderLabel="Destination:"
              selectedFolderLabelWidth={115}
              subHeight={415}
              storageManager={storageManager}
            />
            { this.getSelectWoMemfisControl() }
          </ModalBodyContainer>
        </Modal.Body>
        <Modal.Footer>
          {isHiddenFolderExplorer && isHiddenSelectWoMemfis ?
            <>
              <Button
                bsStyle="primary"
                onClick={this.handleConfirmCreateChecksumReport}
                disabled={isInvalidReportName}
              >
                Submit
              </Button>
              <Button
                onClick={this.handleClose}
              >
                Cancel
              </Button>
            </> : null
          }
          { !isHiddenFolderExplorer ?
            <>
              <Button
                bsStyle="primary"
                onClick={this.handleConfirmSelectedDestination}
                disabled={!preselectedDestFolder}
              >
                Continue
              </Button>
              <Button
                onClick={this.handleCloseSelectingDestination}
              >
                Back
              </Button>
            </> : null
          }
          { !isHiddenSelectWoMemfis ?
            <>
              <Button
                onClick={this.handleCloseWoChooser}
              >
                Back
              </Button>
              <Button
                onClick={this.handleClose}
              >
                Close
              </Button>
            </> : null
          }
        </Modal.Footer>
      </RndModal>
    );
  }
}

const ModalBodyContainer = styled.div`
  &.select-wo {
    height: 60vh;
    overflow-y: auto;
  }
`;

const FormGroupStyled = styled(FormGroup)`
  display: flex;
  align-items: center;
  .control-label {
    margin: 0;
    padding: 0;
    flex: 0 0 140px;
    + div, + .form-control, + .input-group {
      flex-grow: 1;
    }
  }
  .shift-left + .shift-left {
    margin-left: 25px;
  }
  .btn-in-field {
    margin-left: 15px;
    flex: 0 0 43px;
  }
  .destination-dir {
    max-width: calc(100% - 140px - ((43px + 15px) * 2));
  }
  .Select-menu-outer {
    z-index: 3;
  }
  .fa-calendar-day {
    margin-right: 5px;
  }
  .btn.ml15 {
    margin-left: 15px;
  }
  .help-tooltip {
    width: 18px;
    height: 18px;
    display: inline-block;
    vertical-align: text-bottom;
    margin-left: 3px;
    color: #555;
  }
`;

ChecksumReportModal.propTypes = {
  dispatch: PropTypes.func.isRequired,
  storageManager: PropTypes.object.isRequired,
  items: PropTypes.array.isRequired,
};

export default ChecksumReportModal;
