import React, {Component} from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import DetailsInfo from './DetailsInfo';
import PasswordInfo from './PasswordInfo';
import Priority from './Priority';
import TargetRateInfo from './TargetRateInfo';
import ActionsTree from './ActionsTree';
import Loader from '../../../../../components/Loader';
import {Button} from 'react-bootstrap';
import {getUploadedFiles, refreshAsperaLinkInfo, stopOrRestartAsperaLink} from '../../../actions';
import {parseStaleOrFailedStatus, WoQcStatuses} from '../../../utils';
import {isUnlinkInProgressStatus, isPartiallyCompletedStatus} from '../../../constants';
import {DELIVERY_PAGE__EDIT, DELIVERY_PAGE__APPROVE, DELIVERY_PAGE__VIEW} from '../../../../DeliveryManager/constants';
import ReportBugButton from "../../../../../components/ReportBugButton";
import {timeFromNow} from "../../../../../utils";

class InfoPanel extends Component {

  renderUploadInfo(item) {
    const {dispatch, isUploadedFile} = this.props;
    const {upload_id} = item;
    const excludedFields = ['groupId', 'upload_id', 'uploadID', 'woMemfis', 'WOMemfis', 'client_name', 'clientName',
      'files', 'file_size_bytes', 'file_size', 'file_name', 'ingest_status_advanced', 'ingest_status', 'status',
      'updatedOnSec', 'updated_on_sec', 'updatedOn', 'updated_on', 'is_deleted', 'actionID', 'rootName', 'clientNameAlt'
    ].concat(isUploadedFile ? ['file_id', 'mam_path'] : []);
    const keys = Object.keys(item).filter(k => !excludedFields.includes(k));
    const fileExtraFields = {WOMemfis: 'WO #', client_name: 'Client', updated_on: 'Updated On'};
    return (
      <>
        <div className="info-field">
          <div className="info-field-name">
            Upload ID
          </div>
          <div className="info-field-value">
            {upload_id}
          </div>
          {
            isUploadedFile ? null :
              <div className="info-field-value">
                <Button
                  bsStyle="default"
                  onClick={() => dispatch(getUploadedFiles(item))}
                  title="Click to view"
                >
                  Uploaded files
                </Button>
              </div>
          }
        </div>
        {
          !isUploadedFile ? null :
            <>
              {Object.keys(fileExtraFields).map((k, i) => (
                item[k] ?
                  <div className="info-field" key={`info-field-${upload_id}-e${i}`}>
                    <div className="info-field-name">
                      {fileExtraFields[k]}
                    </div>
                    <div className="info-field-value">
                      {item[k]}
                    </div>
                  </div> : null
              ))}
            </>
        }
        {keys.map((k, i) => (
          <div className="info-field" key={`info-field-${upload_id}-${i}`}>
            <div className="info-field-name field-name-capitalized">
              {k.replace(/_/g, ' ').replace(/([A-Z]|[0-9]+)/g, ' $1').replace(/\bid\b/i, 'ID').replace(/\buuid\b/i, 'UUID')}
            </div>
            <div className={`info-field-value${item[k] !== null ? '' : ' no-value'}`}>
              {item[k] !== null ? item[k] : 'None'}
            </div>
          </div>
        ))}
      </>
    );
  }

  renderActionsTree(item, actionIdFromStatus) {
    const {notLockedRoots, actionsIsLoading, onRestartAction} = this.props;
    const isUnlinkInProgress = isUnlinkInProgressStatus(item.status) ||
                               isUnlinkInProgressStatus(item.ingest_status) ||
                               isUnlinkInProgressStatus(item.ingest_status_advanced);
    const {assetData} = item;
    const {unlinkActions, ingestActions, deliveryData} = (assetData || {});
    const showDeliveryActions = Array.isArray((deliveryData || {}).deliveryActions) && !!deliveryData.deliveryActions.length;
    return (
      <>
        <div className="info-field">
          <div className="info-field-name">
            {showDeliveryActions ? 'Ingest ' : ''}Actions
          </div>
          <div className="info-field-value">
            {
              (actionsIsLoading && !(isUnlinkInProgress ? unlinkActions : ingestActions)) ?
                  <div>
                    <Loader/>
                    <ReportBugButton isForModal className="loader-report-bug-btn"/>
                  </div> :
                <ActionsTree
                  linkActions={isUnlinkInProgress ? unlinkActions : ingestActions}
                  notLockedRoots={notLockedRoots}
                  actionIdFromStatus={actionIdFromStatus}
                  onRestartAction={onRestartAction}
                />
            }
          </div>
        </div>
        {showDeliveryActions &&
          <div className="info-field">
            <div className="info-field-name">
              Delivery Actions
            </div>
            <div className="info-field-value">
              <ActionsTree
                linkActions={deliveryData.deliveryActions}
                notLockedRoots={notLockedRoots}
                actionIdFromStatus={actionIdFromStatus}
                onRestartAction={onRestartAction}
              />
            </div>
          </div>
        }
      </>
    );
  }

	renderSetOperationTypeBtn(item) {
		const {onSetOperationType} = this.props;

		if (!(!!item.need_set_operation_type)) {
			return null;
		}

		return (
      <>
				<div className="info-field">
					<Button
						bsStyle="default"
						title="Ingest"
						onClick={() => onSetOperationType()}
						disabled={!onSetOperationType || !!item.is_deleted}
					>
						<i className="fa-regular fa-fw fa-gears"/>
					</Button>
				</div>
      </>
		);
	}

  renderDeliveryBtn(item) {
    const {onDeliveryMemfisWO, onRefreshDeliveryData} = this.props;
    const {assetData, WOMemfis} = item;
    const {deliveryData} = (assetData || {});

    if (!onDeliveryMemfisWO
      || !deliveryData
      || (item.status === WoQcStatuses.DONE && !deliveryData.delivery)) {
      return null;
    }

    const {deliveryMemfisWO, delivery, nonReachedStatusWOMemfisMap} = deliveryData;
    let deliveryBtn = (
      <Button
        bsStyle="default"
        title="Start Delivery WO#"
        onClick={() => onDeliveryMemfisWO(deliveryMemfisWO)}
        disabled={!delivery && !!nonReachedStatusWOMemfisMap}
      >
        Start Delivery
      </Button>
    );
    if (delivery) {
      const {actionID, redirect} = delivery;
      if (actionID && [DELIVERY_PAGE__EDIT, DELIVERY_PAGE__APPROVE, DELIVERY_PAGE__VIEW].includes(redirect)) {
        const operation = redirect.substr(0, 1).toUpperCase() + redirect.substr(1).toLowerCase();
        const isDisabled = redirect === DELIVERY_PAGE__VIEW;
        deliveryBtn = (
          <Button
            bsStyle="default"
            title={isDisabled ? 'Delivery is not possible now' : `${operation} Delivery WO#`}
            onClick={() => onDeliveryMemfisWO(deliveryMemfisWO, redirect, actionID)}
            disabled={isDisabled}
          >
            {operation} Delivery
          </Button>
        );
      }
    }

    return (
      <>
        <div className="info-field">
          <Button
            bsStyle="default"
            title="Refresh Delivery Data"
            onClick={() => onRefreshDeliveryData(WOMemfis)}
          >
            <i className="fas fa-sync"/>
          </Button>
          {deliveryBtn}
        </div>
        {!!nonReachedStatusWOMemfisMap &&
          <div className="info-field">
            <div className="info-field-name">
              Non-reached status WO# for delivery
            </div>
            <div className="info-field-value">
              <NonReachedStatusWOMemfis>
                <tbody>
                  {Object.keys(nonReachedStatusWOMemfisMap).map(woMemfisId =>
                    <tr key={`nonReachedStatusWOMemfis-${woMemfisId}`}>
                      <td title="WO#">
                        <strong>{woMemfisId}</strong>
                      </td>
                      <td title="Status">
                        {nonReachedStatusWOMemfisMap[woMemfisId].replace(/_/g, ' ')}
                      </td>
                    </tr>
                  )}
                </tbody>
              </NonReachedStatusWOMemfis>
            </div>
          </div>
        }
      </>
    );
  }

  onClickStopDownloadAsperaLink(asperaLinkId) {
    const {dispatch} = this.props;
    dispatch(stopOrRestartAsperaLink(asperaLinkId, 'stop'));
  }

  onClickRestartDownloadAsperaLink(asperaLinkId) {
    const {dispatch} = this.props;
    dispatch(stopOrRestartAsperaLink(asperaLinkId, 'restart'));
  }

  onClickDisableXxHashAsperaLink(asperaLinkId) {
    const {dispatch} = this.props;
    dispatch(stopOrRestartAsperaLink(asperaLinkId, 'disable_xxh'));
  }

  onClickRefreshAsperaLinkInfo() {
    let test = 1;
    console.log('onClickRefreshAsperaLinkInfo');
    const {dispatch, items} = this.props;
    const asperaLinkItem = items[0];
    if ((asperaLinkItem.flags & 2048) === 0) {
      return;
    }

    dispatch(refreshAsperaLinkInfo(asperaLinkItem));
  }

  renderButtonsForManualLinks(item) {
    // only for manual download links
    if ((item.flags & 2048) === 0) {
      return null;
    }

    let stopRestartButtons = null;
    // stop and restart buttons only when status not new or completed
    if (item.status_id > 1) {
      const restartBtnEnabled = (item.flags & 8192) !== 0;
      const disableRestartBtn = !restartBtnEnabled;
      const disableStopBtn = restartBtnEnabled || item.status_id !== 2;

      let restartingMsg = null;
      if (item.status === 'RESTARTING' && item.updated_on_sec) {
        const passedMinutes = timeFromNow(item.updated_on_sec);
        restartingMsg = (
            <div className="restart-info">Restarting {passedMinutes}</div>
        );
      }

      let disableXxhBlock;
      const alreadyDisabledXxh = (item.flags & 65536) !== 0;
      if (alreadyDisabledXxh) {
        disableXxhBlock = (
            <div>xxHash disabled</div>
        );
      } else {
        disableXxhBlock = (
            <Button
                bsStyle="default"
                className="btn"
                title="Disable xxHash"
                onClick={() => this.onClickDisableXxHashAsperaLink(item.link_id)}
            >
              Disable xxHash
            </Button>
        );
      }

      stopRestartButtons = (
          <>
          <Button
              bsStyle="default"
              className="btn"
              title="Stop downloading"
              onClick={() => this.onClickStopDownloadAsperaLink(item.link_id)}
              disabled={disableStopBtn}
          >
            Stop
          </Button>
          <Button
              bsStyle="default"
              className="btn"
              title="Restart downloading"
              onClick={() => this.onClickRestartDownloadAsperaLink(item.link_id)}
              disabled={disableRestartBtn}
          >
            Restart
          </Button>
          {disableXxhBlock}
          {restartingMsg}
          </>
      );
    }

    return (
        <div className="manual-buttons">
          <Button
              bsStyle="default"
              className="btn"
              title="Refresh info for current Aspera link"
              onClick={() => this.onClickRefreshAsperaLinkInfo()}
          >
            Refresh info
          </Button>
          {stopRestartButtons}
        </div>
    );
  }

  renderSingleItemInfo(item) {
    const {
      minRate, maxRate, onChangeTargetRate, isUploadedFile, onChangePassword, onChangePriority, onMarkCompleted
    } = this.props;
    if (isUploadedFile) {
      return this.renderUploadInfo(item);
    }

    const {is_manual, message_id, WOMemfis, upload_id} = item;
    const temp = parseStaleOrFailedStatus(item.status || '');
    const isPartiallyCompleted = isPartiallyCompletedStatus(item.status) ||
                                 isPartiallyCompletedStatus(item.ingest_status) ||
                                 isPartiallyCompletedStatus(item.ingest_status_advanced);
    return (
      <>
        {upload_id ? this.renderUploadInfo(item) : null}
        {is_manual ? null :
          <>
            {!!WOMemfis && this.renderDeliveryBtn(item)}
            {!!WOMemfis && this.renderSetOperationTypeBtn(item)}
            {this.renderButtonsForManualLinks(item)}
            <div className="info-field">
              <div className="info-field-name">
                Password
              </div>
              <div className="info-field-value">
                <PasswordInfo
                  link={item}
                  onChange={onChangePassword}
                />
              </div>
            </div>
            <div className="info-field">
              <div className="info-field-name">
                Target Rate, kbps
              </div>
              <div className="info-field-value">
                <TargetRateInfo
                  link={item}
                  minRate={minRate}
                  maxRate={maxRate}
                  onChangeRate={onChangeTargetRate}
                />
              </div>
            </div>
            <div className="info-field">
              <div className="info-field-name">
                Current Speed, kbps
              </div>
              <div className="info-field-value">
                {item.DownloadSpeed>0 ? item.DownloadSpeed/125 : 'n/a'}
              </div>
            </div>
            {
              (!!item.allowChangePriority || (Array.isArray(item.links) && !!item.links.find(i => !!i.allowChangePriority))) &&
                <div className="info-field">
                  <div className="info-field-name">
                    Priority
                  </div>
                  <div className="info-field-value">
                    <Priority
                      link={item}
                      onChange={onChangePriority}
                    />
                  </div>
                </div>
            }
          </>
        }
        {
          (message_id || (isPartiallyCompleted && onMarkCompleted)) ?
            <div className="info-field">
              <div className="info-field-name">
                Details
              </div>
              <div className="info-field-value">
                <DetailsInfo
                  data={item}
                  onMarkCompleted={isPartiallyCompleted ? onMarkCompleted : undefined}
                />
              </div>
            </div> : null
        }
        {!!WOMemfis && this.renderActionsTree(item, Number(temp && temp.length > 2 ? temp[2] : null))}
      </>
    );
  }

  render() {
    const {items} = this.props;
    return (
      <StyledInfoPanel>
        {items.length === 1 ? this.renderSingleItemInfo(items[0]) : null}
      </StyledInfoPanel>
    );
  }
}

const StyledInfoPanel = styled.div`
  .info-field {
    + .info-field {
      margin-top: 20px;
    }
    &:last-child {
      margin-bottom: 20px;
    }
    .info-field-name {
      font-weight: bold;
      &.field-name-capitalized {
        text-transform: capitalize;
      }
    }
    .info-field-value {
      position: relative;
      word-break: break-word;
      &.no-value {
        opacity: 0.65;
      }
      button {
        margin-top: 3px;
      }
    }
    .btn + .btn {
      margin-left: 15px;
    }
  }

  .manual-buttons {
    .btn + .btn {
      margin-left: 10px;
    }

    .restart-info {
      color: darkred;
      margin: 5px 0;
    }
  }
`;

const NonReachedStatusWOMemfis = styled.table`
  border: none !important;
  * {
    border: none !important;
  }
  td {
    color: #757575;
  }
`;

InfoPanel.defaultProps = {
  dispatch: () => {},
  items: [],
  minRate: 0,
  maxRate: 0,
  onChangeTargetRate: () => {},
  onChangePassword: () => {},
  actionsIsLoading: false,
  onRestartAction: () => {}
};

InfoPanel.propsTypes = {
  dispatch: PropTypes.func.isRequired,
  items: PropTypes.array.isRequired,
  isUploadedFile: PropTypes.bool,
  minRate: PropTypes.number.isRequired,
  maxRate: PropTypes.number.isRequired,
  onChangeTargetRate: PropTypes.func.isRequired,
  onChangePassword: PropTypes.func.isRequired,
  onChangePriority: PropTypes.func,
  onMarkCompleted: PropTypes.func,
  onDeliveryMemfisWO: PropTypes.func,
	onSetOperationType: PropTypes.func,
  onRefreshDeliveryData: PropTypes.func,
  notLockedRoots: PropTypes.array,
  actionsIsLoading: PropTypes.bool.isRequired,
  onRestartAction: PropTypes.func.isRequired
};

export default InfoPanel;
