/* eslint "jsx-a11y/anchor-is-valid":"off" */
import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';
import {Link} from "react-router-dom";
import styled, {css} from 'styled-components';
import {Row, Col, Button, ButtonGroup, ButtonToolbar} from 'react-bootstrap';
import {formatBytes, formatDate, PARENT_DIR_REGEX_FOR_REPLACE} from '../../../../utils';
import {
  compileSummaryOfDeletion,
  buildSummaryInfo,
  isDeletedStatus,
  isWorkOrderRoot,
  isReconcileDeleted,
  isRootItem,
  getValueForOS,
  preparePathForOS,
} from '../../utils';
import {
  reconcileFsItem,
  findDeletedAsset,
  startManuallyReconcileFsItem,
  refreshCheckedContent,
  applyNavigation,
  loadActionPermissions,
  executeDemuxAction,
  reconcileSymlinkItem,
} from '../../actions';
import {
  isAllowedFileAction,
  LKFSButton,
  GenerateChecksumButtons,
  DownloadButton,
  PSEButton,
  MediaInfoButton,
  CopyToQodButton,
  DeleteSymlinkButton,
  AttachToWoButton,
  CaptionsButton,
  MetafierButton,
  EdgePassButton,
  ScreenToQodButton,
  HdrReportButton
} from '../buttons/FsActions';
import Collapsible from "react-collapsible";
import store from "../../../../store";

class InfoPanel extends Component {
  state = {
    expandedSymLinks: {},
    itemKey: null
  }

  addFieldInfo = (fields, label, value, render) => {
    fields.push({label, value, render});
  };

  renderNavLink({label, value, link, checkItemWithID}) {
    return <Row key={`${value}'_nav-link'`}><Col md={4} xs={4}>
        <label>
          {`${label}:`}
        </label>
      </Col>
      <Col md={8} xs={8}>
        { link ? <a href='#' onClick={(event) => {
          event.preventDefault();
          this.onClickNavLink(link, checkItemWithID)
        }}>{value}</a> : <span>{value}</span> }
      </Col>
    </Row>
  }

  renderNavSymLink({label, value}) {
    const {storageManager: {fsRoots}} = this.props;
    return <Row key={`${label}'_nav-link'`}><Col md={4} xs={4}>
        <label>
          {`${label}:`}
        </label>
      </Col>
      <Col md={8} xs={8}>
        {value.map(linkItem => {
          const opened = !!this.state.expandedSymLinks[linkItem.memfisWoID];
          const fsRoot = fsRoots.find(r => r.fsRootID === linkItem.fsRootID);
          return (
            <StyledSymLink key={`${linkItem.key}'_nav-link'`}>
              <Collapsible
                accordionPosition={`position-${linkItem.memfisWoID}`}
                open={opened}
                handleTriggerClick={() => {this.setState({expandedSymLinks: {...this.state.expandedSymLinks, [linkItem.memfisWoID]: !opened}});}}
                trigger={
                  <CollapsibleHead opened={opened} title={`Click to ${opened ? 'collapse' : 'expand'}`}>
                    <Link
                      to={`/storage?wo_memfis=${linkItem.memfisWoID}&with_file_symlinks=true&use_max_modified_on=true&last_segment_only=true`}
                      target="_blank"
                      title="View by WO#"
                      onClick={(event) => {event.stopPropagation();}}
                    >{linkItem.memfisWoID}</Link>
                    <i className="pull-right fa fa-angle-down"/>
                  </CollapsibleHead>
                }
              >
                <div className="symlink--nav-link">
                  <a href='#' onClick={(event) => {
                    event.preventDefault();
                    const link = linkItem.key.replace(PARENT_DIR_REGEX_FOR_REPLACE, '');
                    this.onClickNavLink(link, linkItem.contentID)
                  }}
                  >{preparePathForOS(linkItem.key, fsRoot) || linkItem.key}</a>
                </div>
              </Collapsible>
            </StyledSymLink>
          );
        })}
      </Col>
    </Row>
  }

  onClickNavLink(link, checkItemWithID) {
    const {dispatch, history} = this.props;
    dispatch(applyNavigation(history, link, () => {}, checkItemWithID));
  }

  renderFields(fields) {
    return (
      <Fragment>
        {
          fields.map((field, index) => {
            const {render} = field;
            return render ? render(field) : <Row key={`info-field-${index}`}>
              <Col md={4} xs={4}>
                <label>
                  {`${field.label}:`}
                </label>
              </Col>
              <Col md={8} xs={8}>
                {field.value}
              </Col>
            </Row>
            }
          )
        }
      </Fragment>
    );
  };

  renderMultiFileInfo(items) {
    const selected = [];
    const selectedRoots = items.filter(isRootItem).length;
    const selectedFiles = items.filter(item => !item.isDirectory).length;
    const selectedFolders = items.length - selectedFiles - selectedRoots;
    if (selectedRoots) {
      selected.push(`${selectedRoots} Volume${selectedRoots > 1 ? 's' : ''}`);
    }
    if (selectedFolders) {
      selected.push(`${selectedFolders} Folder${selectedFolders > 1 ? 's' : ''}`);
    }
    if (selectedFiles) {
      selected.push(`${selectedFiles} File${selectedFiles > 1 ? 's' : ''}`);
    }
    let size = 0;
    items.forEach(item => {
      size += item.size;
    });
    const fields = [];
    this.addFieldInfo(fields, 'Selected', selected.join(', '));
    this.addFieldInfo(fields, 'Size', formatBytes(size));
    return this.renderFields(fields);
  }

  renderSingleFileInfo(item) {
    const {isShowFilePath, isShowNumberOfRecords} = this.props;

    const render = ({label, value}) => {
      const link = item.relativeStatus === 'LIVE' ?
        item.relativeFileName.replace(PARENT_DIR_REGEX_FOR_REPLACE, '')
        : undefined;
      return this.renderNavLink({
        label,
        value,
        link,
        checkItemWithID: item.contentID,
      });
    };

    const directoryField = [{
      label: 'File Count',
      value: 'count',
    }];

    const itemSymLinksField = [{
      label: 'SymLinks',
      value: 'symlinks',
      render: ({label, value}) => this.renderNavSymLink({
          label,
          value,
        })
    }];
    const symlinkFields = [
      {
        label: 'Relative file name',
        value: 'relativeFileName',
        render,
      },
      {
        label: 'Relative Path',
        value: 'relativePath',
        render,
      },
      {
        label: 'Relative Status',
        value: 'relativeStatus'
      }
    ];
    const fieldsDescription = [
      {
        label: 'Name',
        value: 'name',
        getValue: (row) => getValueForOS(row, 'name')
      },
      {
        label: 'Path',
        value: 'fullPath',
        getValue: (row) => getValueForOS(row, 'fullPath'),
        skip: isShowFilePath === false
      },
      {
        label: 'WO#',
        value: 'memfisWoID'
      },
      {
        label: 'Element ID',
        value: 'barCode'
      },
      {
        label: 'MD5 Checksum',
        value: 'md5Checksum'
      },
      {
        label: 'SHA1 Checksum',
        value: 'sha1Checksum'
      },
      {
        label: 'xxHash Checksum',
        value: 'xxhChecksum'
      },
      {
        label: 'Size',
        value: formatBytes(item.size)
      },
      {
        label: 'Modified On',
        value: formatDate(item.modifiedOn, '', 'MM/DD/YYYY')
      },
      {
        label: 'Owner',
        value: 'owner'
      },
      {
        label: 'Group',
        value: 'group'
      },
      {
        label: 'Permissions',
        value: 'permissions'
      },
      {
        label: 'Content ID',
        value: 'contentID'
      },
      {
        label: 'Number of records',
        value: 'count',
        skip: isShowNumberOfRecords !== true
      },
      ...(item.isSymlink ? symlinkFields : []),
      ...((item.symlinks && item.symlinks.length > 0) ? itemSymLinksField : []),
      ...(item.isDirectory ? directoryField : []),
    ];

    const fields = [];
    fieldsDescription.forEach(field => {
      const value = ['Size', 'Modified On'].includes(field.label) ? field.value
        : field.label === 'Content ID' ? (item[field.value] > 0 ? item[field.value] : '')
          : field.value === 'count' ? item[field.value] - 1 : field.getValue ? field.getValue(item) : item[field.value];
      if (!field.skip) {
        this.addFieldInfo(fields, field.label, value, field.render);
      }
    });
    return this.renderFields(fields);
  }

  renderSummaryOfDeletion() {
    const {items} = this.props;
    const summary = compileSummaryOfDeletion(items);
    return buildSummaryInfo(summary);
  }

  isFalseCouldBeReconciled = item => item.couldBeReconciled === false;

  renderReconcileButton() {
    const {items, dispatch, history, isSearchWoMemfis} = this.props;
    if (items.length === 1 && items[0].memfisWoID && isDeletedStatus(items[0]) && isWorkOrderRoot(items[0])) {
      return items[0].afterSearchDeletedAsset ? (
        <>
          <Button
            bsStyle="default"
            onClick={() => dispatch(reconcileFsItem())}
            disabled={this.isFalseCouldBeReconciled(items[0])}
          >
            Reconcile
          </Button>
          {this.isFalseCouldBeReconciled(items[0]) &&
            <Button
              bsStyle="default"
              onClick={() => dispatch(startManuallyReconcileFsItem())}
            >
              Manually Reconcile
            </Button>
          }
        </>
      ) : (!isReconcileDeleted(items[0]) && isSearchWoMemfis) ? (
        <Button
          bsStyle="default"
          onClick={() => dispatch(findDeletedAsset(history))}
        >
          Find deleted asset
        </Button>
      ) : null;
    }
    return null;
  }

  renderRefreshButton() {
    const {dispatch, items, allowRefresh} = this.props;
    return (allowRefresh && items.length === 1 && items[0].contentID > 0) ? (
      <Button
        bsStyle="default"
        title="Refresh"
        onClick={() => dispatch(refreshCheckedContent())}
        >
        <i className="fa-regular fa-arrows-rotate"/>
      </Button>
    ) : null;
  }

  renderDemuxButton(isDisabled) {
    const {dispatch, items} = this.props;
    const {fsRootID, contentID} = items[0];
    return (
      <Button
        bsStyle="default"
        title="DEMUX"
        onClick={() => dispatch(executeDemuxAction(fsRootID, [contentID]))}
        disabled={isDisabled}
        >
				<i className="fa-regular fa-split"/>
      </Button>
    );
  }

  renderReconcileSymlinkButton() {
    const { dispatch, items } = this.props;

    if (items.length === 1 && items[0].contentID > 0 && items[0].isSymlink) {
      return (
        <Button
          bsStyle="default"
          onClick={() => dispatch(reconcileSymlinkItem())}
        >
          Reconcile
        </Button>
      );
    }

    return null;
  }

  componentDidMount() {
    const {dispatch} = this.props;
    dispatch(loadActionPermissions());
  }

  render() {
    const {items, isSummaryOfDeletion, storageManager, dispatch} = this.props;
    const {actionPermissions} = storageManager;
    // const isAllowedDemuxAction = actionPermissions && checkIsAllowedAction(actionPermissions.DEMUX, items);
    const isVisibleNonUsableDemuxBtn = actionPermissions && actionPermissions.DEMUX.UI.visibility.nonUsable === 'disabled';

    const {email} = store.getState().user;
    const showHdrReportBtn = email && (email.includes('berkman') || email.includes('morozov'));

    const isAllowedDemuxAction = isAllowedFileAction(storageManager, 'DEMUX_PORTAL', items);

    const reconcileBtn = this.renderReconcileButton();
    const refreshBtn = this.renderRefreshButton();
    const miBtn = MediaInfoButton({dispatch, storageManager, items, forInfoPanel: true});
    const lkfsBtn = LKFSButton({dispatch, storageManager, items, forInfoPanel: true});
    const captionsBtn = CaptionsButton({dispatch, storageManager, items, forInfoPanel: true});
    const pseBtn = PSEButton({dispatch, storageManager, items, forInfoPanel: true});
    const genChecksumBtn = GenerateChecksumButtons({dispatch, storageManager, items, forInfoPanel: true});
    const downloadBtn = DownloadButton({dispatch, storageManager, items, forInfoPanel: true});
    const edgePassBtn = EdgePassButton({dispatch, storageManager, items, forInfoPanel: true});
    const screenToQodBtn = ScreenToQodButton({dispatch, storageManager, items, forInfoPanel: true});
    const copyToQodBtn = CopyToQodButton({dispatch, storageManager, items, forInfoPanel: true});
    const attachToWoBtn = AttachToWoButton({dispatch, storageManager, items, forInfoPanel: true});
    const deleteSymlinkBtn = DeleteSymlinkButton({dispatch, storageManager, items, forInfoPanel: true});
    const metafierButton = MetafierButton({dispatch, storageManager, items, forInfoPanel: true});
    const hdrReportButton = showHdrReportBtn && HdrReportButton({dispatch, storageManager, items, forInfoPanel: true});
    const reconcileSymlinkBtn = this.renderReconcileSymlinkButton();
    const group4 = Boolean(lkfsBtn || pseBtn || genChecksumBtn || captionsBtn) && (
      <>
        {lkfsBtn}
        {pseBtn}
        {genChecksumBtn}
        {captionsBtn}
        {metafierButton}
        {edgePassBtn}
        {screenToQodBtn}
        {hdrReportButton}
      </>
    );
    const group5 = Boolean(downloadBtn || copyToQodBtn || attachToWoBtn) && (
      <>
        {downloadBtn}
        {copyToQodBtn}
        {attachToWoBtn}
      </>
    );

    return (
      <StyledInfoPanel>
        <ButtonToolbar>
          {[reconcileBtn, refreshBtn, miBtn, group4, group5, deleteSymlinkBtn, reconcileSymlinkBtn].map((g, i) => Boolean(g) && (
            <ButtonGroup key={`btn--group--${i}`}>
              {g}
            </ButtonGroup>
          ))}
          {isAllowedDemuxAction ? this.renderDemuxButton(false) : (isVisibleNonUsableDemuxBtn ? this.renderDemuxButton(true) : null) }
        </ButtonToolbar>
        {isSummaryOfDeletion ? <>{this.renderSummaryOfDeletion()}<br/><hr/></> : null}
        {items.length === 1 ? this.renderSingleFileInfo(items[0]) : this.renderMultiFileInfo(items)}
      </StyledInfoPanel>
    );
  }
}

const StyledInfoPanel = styled.div`
  padding: 5px 0 5px 15px;
  border-left: 3px solid #ddd;
  max-height: calc(100vh - 71px - 150px);
  overflow-y: auto;
  overflow-x: hidden;
  width: calc(25% - 15px);
  transition: width 0.3s ease-in-out 0s ease-in-out 0s display 0s ease-in-out 0.3s;

  @media (max-width: 767px) {
    max-height: calc(100vh - 71px - 210px);
  }

  .table-wrapper.info-panel-collapsed & {
    width: 0;
    display: none;
  }

  .col-md-8, .col-xs-8 {
    word-break: break-all;
  }

  .btn-toolbar {
    margin-left: -10px;
    margin-bottom: 10px;
    > .btn, > .btn-group, > .input-group {
      margin-left: 10px;
      margin-bottom: 10px;
    }
  }
`;

const StyledSymLink = styled.div`
  margin-right: 10px;
  font-size: 12px;
  line-height: 15px;
  .symlink--nav-link {
    margin: 0 10px 10px;
  }
`;

const CollapsibleHead = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 6px;
  margin-bottom: 6px;
  background: #f7f7f7;
  cursor: pointer;
  i {
    color: #999;
    transition: transform 0.2s ease 0s;
    will-change: transform;
  }
  ${props => props.opened && css`
    i {
      transform: rotate(-90deg);
    }
  `}
  :hover {
    background: #D0D3D4;
    i {
      color: #555;
    }
  }
`;

InfoPanel.propsTypes = {
  dispatch: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  storageManager: PropTypes.object.isRequired,
  items: PropTypes.array.isRequired,
  isSummaryOfDeletion: PropTypes.bool.isRequired,
  isShowFilePath: PropTypes.bool,
  isSearchWoMemfis: PropTypes.any,
  isShowNumberOfRecords: PropTypes.bool,
  allowRefresh: PropTypes.bool,
};

export default InfoPanel;
